/* InvoiceTextForm.css */
:root {
    --invoice-primary: #4a6cf7;
    --invoice-primary-light: #e8effd;
    --invoice-secondary: #6c757d;
    --invoice-danger: #dc3545;
    --invoice-success: #28a745;
    --invoice-dark: #343a40;
    --invoice-light: #f8f9fa;
    --invoice-white: #ffffff;
    --invoice-gray: #adb5bd;
    --invoice-border: rgba(0, 0, 0, 0.1);
    --invoice-text: #495057;
    --invoice-radius: 10px;
    --invoice-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    --invoice-transition: all 0.2s ease-in-out;
  }
  
  .invoice-text-form-container {
    padding: 1.5rem;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .invoice-text-form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .back-button {
    background: none;
    border: none;
    color: var(--invoice-primary);
    font-size: 1.25rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: var(--invoice-transition);
  }
  
  .back-button:hover {
    background-color: var(--invoice-primary-light);
  }
  
  .form-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--invoice-dark);
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .form-title-icon {
    margin-right: 0.75rem;
    color: var(--invoice-primary);
  }
  
  .invoice-text-form-card {
    background-color: var(--invoice-white);
    border-radius: var(--invoice-radius);
    box-shadow: var(--invoice-shadow);
    padding: 2rem;
    margin-bottom: 2rem;
    border: 1px solid var(--invoice-border);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--invoice-dark);
  }
  
  .form-label-icon {
    margin-right: 0.5rem;
    color: var(--invoice-primary);
    font-size: 1rem;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    /* padding: 0.75rem; */
    border: 1px solid var(--invoice-border);
    border-radius: var(--invoice-radius);
    font-size: 0.95rem;
    color: var(--invoice-text);
    background-color: var(--invoice-white);
    transition: var(--invoice-transition);
  }
  
  .form-input:focus,
  .form-textarea:focus {
    outline: none;
    border-color: var(--invoice-primary);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .form-input::placeholder,
  .form-textarea::placeholder {
    color: var(--invoice-gray);
  }
  
  .form-input:disabled,
  .form-textarea:disabled {
    background-color: var(--invoice-light);
    cursor: not-allowed;
  }
  
  .input-error {
    border-color: var(--invoice-danger);
  }
  
  .error-message {
    color: var(--invoice-danger);
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }
  
  .text-area-container {
    position: relative;
  }
  
  .form-textarea {
    resize: vertical;
    min-height: 150px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.5;
  }
  
  .textarea-helptext {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--invoice-primary-light);
    border-radius: var(--invoice-radius);
    font-size: 0.9rem;
  }
  
  .textarea-helptext p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .variables-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.5rem;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .variables-list li {
    display: flex;
    align-items: center;
  }
  
  .variables-list code {
    background-color: var(--invoice-white);
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 0.85rem;
    color: var(--invoice-primary);
    margin-right: 0.5rem;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .cancel-button {
    background-color: var(--invoice-light);
    border: 1px solid var(--invoice-border);
    color: var(--invoice-secondary);
    padding: 0.75rem 1.5rem;
    border-radius: var(--invoice-radius);
    font-weight: 500;
    cursor: pointer;
    transition: var(--invoice-transition);
  }
  
  .cancel-button:hover {
    background-color: var(--invoice-secondary);
    color: var(--invoice-white);
  }
  
  .cancel-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .submit-button {
    background-color: var(--invoice-primary);
    border: none;
    color: var(--invoice-white);
    padding: 0.75rem 1.5rem;
    border-radius: var(--invoice-radius);
    font-weight: 500;
    cursor: pointer;
    transition: var(--invoice-transition);
    display: flex;
    align-items: center;
  }
  
  .submit-button:hover {
    background-color: #3a5ad9; /* Slightly darker than primary */
    box-shadow: 0 4px 10px rgba(74, 108, 247, 0.2);
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  