/* Client Activities Styles */
:root {
    --primary-color: #4a6cf7;
    --primary-light: #e8effd;
    --secondary-color: #6c757d;
    --background-color: #f5f7fb;
    --card-bg: #ffffff;
    --border-radius: 10px;
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
  }
  
  .client-activities-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
  
  .client-activities-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
  }
  
  .client-activities-header h2 {
    color: var(--primary-color);
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .select-all-btn {
    background-color: var(--primary-light);
    color: var(--primary-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .select-all-btn:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  .activities-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .activities-section {
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    padding: 1.5rem;
  }
  
  .activities-section h3 {
    color: var(--secondary-color);
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .activities-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .activity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0.75rem;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .activity-item:hover {
    background-color: var(--primary-light);
    border-color: var(--primary-color);
  }
  
  .activity-item.selected {
    background-color: var(--primary-light);
    border-color: var(--primary-color);
  }
  
  .activity-details {
    display: flex;
    flex-direction: column;
  }
  
  .activity-name {
    font-weight: 600;
    color: var(--secondary-color);
  }
  
  .activity-type {
    font-size: 0.75rem;
    color: var(--secondary-color);
    opacity: 0.7;
  }
  
  .activity-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  
  .activity-checkbox svg {
    stroke: var(--secondary-color);
    opacity: 0.5;
  }
  
  .activity-item.selected .activity-checkbox svg {
    stroke: var(--primary-color);
    opacity: 1;
  }
  
  .client-activities-footer {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .submit-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .submit-btn:hover:not(:disabled) {
    background-color: #3a5aeb;
  }
  
  .submit-btn:disabled {
    background-color: var(--secondary-color);
    cursor: not-allowed;
  }
  
  .submit-btn .loading-icon {
    animation: spin 1s linear infinite;
  }
  
  .client-activities-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: var(--primary-color);
  }
  
  .loading-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  