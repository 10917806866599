.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content button {
    margin: 5px;
  }
  
  .App{
    display: flex;
    height: 100%;
  }

  div#root{
    height: 100%;
    display: grid;
  }

  html{
    height: 100%;
  }

  body{
    height: 100%;
  }

  .underline{
    text-decoration: underline;
  }

  .label {
    display: inline-block;
    margin-right: 20%;
}

.total-label {
  display: inline-block;
  margin-right: 20%;
  font-size: 14px;
}

hr.f {
  width:20%;
  margin:5px auto;
  margin-left: 80%;
}