/* AccountsReceivableLedger.css */

/* Main Container */
.arl-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
    color: #333;
  }
  
  /* Header Styles */
  .arl-header {
    margin-bottom: 2rem;
  }
  
  .arl-title-section {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .arl-header-icon {
    font-size: 1.75rem;
    margin-right: 0.75rem;
    color: #2563eb;
    background-color: #dbeafe;
    padding: 0.5rem;
    border-radius: 50%;
  }
  
  .arl-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: #1e3a8a;
    margin: 0;
  }
  
  .arl-subtitle {
    font-size: 1rem;
    color: #6b7280;
    margin: 0;
    margin-left: 3rem;
  }
  
  /* Controls Container */
  .arl-controls-container {
    background-color: #f8fafc;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .arl-section-title {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: #374151;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  .arl-section-title svg {
    margin-right: 0.5rem;
  }
  
  /* Filter Styles */
  .arl-filters {
    margin-bottom: 1.5rem;
  }
  
  .arl-filter-section {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  
  .arl-filter-group {
    display: flex;
    align-items: center;
  }
  
  .arl-filter-label {
    font-weight: 600;
    color: #4b5563;
    width: 80px;
  }
  
  .arl-filter-select {
    flex: 1;
    padding: 0.6rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    background-color: white;
    font-size: 0.875rem;
    color: #111827;
    max-width: 300px;
  }
  
  /* Date Range Styles */
  .arl-date-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .arl-date-filter-btn {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    background-color: #e0e7ff;
    color: #4338ca;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .arl-date-filter-btn:hover {
    background-color: #c7d2fe;
  }
  
  .arl-btn-icon {
    margin-right: 0.375rem;
  }
  
  .arl-date-range {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.75rem;
  }
  
  .arl-date-input-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .arl-date-label {
    font-size: 0.75rem;
    font-weight: 600;
    color: #4b5563;
  }
  
  .arl-date-input {
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  .arl-date-separator {
    align-self: center;
    color: #6b7280;
    font-weight: 500;
    padding-top: 1rem;
  }
  
  /* Action Buttons */
  .arl-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .arl-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .arl-generate-btn {
    background-color: #1d4ed8;
    color: white;
  }
  
  .arl-generate-btn:hover {
    background-color: #1e40af;
  }
  
  .arl-generate-btn:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
  
  .arl-print-btn {
    background-color: #f3f4f6;
    color: #374151;
    border: 1px solid #d1d5db;
  }
  
  .arl-print-btn:hover {
    background-color: #e5e7eb;
  }
  
  /* Loading State */
  .arl-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-color: #f8fafc;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
  }
  
  .arl-loading-icon {
    font-size: 2rem;
    color: #3b82f6;
    margin-bottom: 1rem;
  }
  
  .arl-spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .arl-loading-text {
    font-size: 1rem;
    color: #4b5563;
    margin: 0;
  }
  
  /* Results Section */
  .arl-results {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .arl-report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1.5rem;
    background-color: #f8fafc;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .arl-report-title {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
  }
  
  .arl-report-date {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .arl-report-icon {
    margin-right: 0.5rem;
  }
  
  /* Summary Stats */
  .arl-summary {
    display: flex;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .arl-summary-item {
    flex: 1;
    padding: 1rem 1.5rem;
    text-align: center;
    border-right: 1px solid #e5e7eb;
  }
  
  .arl-summary-item:last-child {
    border-right: none;
  }
  
  .arl-summary-label {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .arl-summary-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
  }
  
  .arl-highlight {
    background-color: #eff6ff;
  }
  
  .arl-highlight .arl-summary-value {
    color: #1d4ed8;
  }
  
  /* Table Styles */
  .arl-table-container {
    overflow-x: auto;
  }
  
  .arl-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
  }
  
  .arl-table thead th {
    background-color: #f9fafb;
    text-align: left;
    padding: 0.75rem 1rem;
    font-weight: 600;
    color: #4b5563;
    border-bottom: 2px solid #e5e7eb;
    white-space: nowrap;
  }
  
  .arl-sortable {
    cursor: pointer;
    position: relative;
  }
  
  .arl-sortable:hover {
    background-color: #f3f4f6;
  }
  
  .arl-sort-icon {
    margin-left: 0.25rem;
    font-size: 0.75rem;
    vertical-align: middle;
  }
  
  .arl-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    color: #374151;
    vertical-align: middle;
  }
  
  .arl-item {
    transition: background-color 0.2s;
  }
  
  .arl-item:hover {
    background-color: #f9fafb;
  }
  
  .arl-invoice-row {
    background-color: #fff;
  }
  
  .arl-payment-row {
    background-color: #f0f9ff;
  }
  
  .arl-type-cell {
    width: 100px;
  }
  
  .arl-type-badge {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .arl-type-badge.arl-invoice {
    background-color: #fee2e2;
    color: #b91c1c;
  }
  
  .arl-type-badge.arl-payment {
    background-color: #dcfce7;
    color: #15803d;
  }
  
  .arl-amount-col {
    text-align: right;
    width: 120px;
    font-weight: 600;
  }
  
  .arl-payment-amount {
    color: #15803d;
  }
  
  .arl-expand-col {
    width: 40px;
    text-align: center;
    cursor: pointer;
    color: #6b7280;
  }
  
  .arl-expand-col:hover {
    color: #111827;
  }
  
  .arl-no-data {
    text-align: center;
    padding: 2rem !important;
    color: #6b7280;
  }
  
  .arl-no-data-icon {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
  
  /* Expanded Item Details */
  .arl-details-row td {
    padding: 0;
    background-color: #f8fafc;
  }
  
  .arl-item-details {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .arl-detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    padding-bottom: 0.625rem;
    border-bottom: 1px dashed #e5e7eb;
  }
  
  .arl-detail-row:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .arl-detail-label {
    font-weight: 500;
    color: #6b7280;
  }
  
  .arl-detail-value {
    font-weight: 500;
    color: #111827;
  }
  
  .arl-detail-value.arl-status {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
  }
  
  .arl-detail-value.arl-status.arl-paid {
    background-color: #dcfce7;
    color: #15803d;
  }
  
  .arl-detail-value.arl-status.arl-unpaid {
    background-color: #fee2e2;
    color: #b91c1c;
  }
  
  .arl-payment-history {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .arl-payment-history-title {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #4b5563;
  }
  
  .arl-payment-history-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.75rem;
  }
  
  .arl-payment-history-table th {
    padding: 0.5rem;
    background-color: #f3f4f6;
    text-align: left;
    font-weight: 600;
    color: #4b5563;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .arl-payment-history-table td {
    padding: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
    background-color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .arl-summary {
      flex-direction: column;
    }
    
    .arl-summary-item {
      border-right: none;
      border-bottom: 1px solid #e5e7eb;
    }
    
    .arl-summary-item:last-child {
      border-bottom: none;
    }
    
    .arl-actions {
      flex-direction: column;
    }
    
    .arl-button {
      width: 100%;
    }
    
    .arl-date-range {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .arl-date-input-group {
      width: 100%;
    }
    
    .arl-date-separator {
      align-self: center;
      padding: 0;
      margin: 0.5rem 0;
    }
  }
  
  /* Print Styles */
  @media print {
    .arl-container {
      padding: 0;
    }
    
    .arl-controls-container {
      display: none;
    }
    
    .arl-summary, 
    .arl-results,
    .arl-table-container {
      box-shadow: none;
      margin-bottom: 1rem;
    }
    
    .arl-expand-col {
      display: none;
    }
    
    .arl-details-row {
      page-break-inside: avoid;
    }
    
    .arl-table {
      page-break-inside: auto;
    }
    
    .arl-item {
      page-break-inside: avoid;
    }
  }