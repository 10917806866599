/* BillingPage.css */

.billing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .billing-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .billing-title-section {
    flex: 1;
  }
  
  .billing-title-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .billing-icon {
    font-size: 1.75rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .billing-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .billing-subtitle {
    color: var(--secondary-color, #6c757d);
    font-size: 1rem;
    margin: 0;
  }
  
  .billing-actions {
    display: flex;
    gap: 1rem;
  }
  
  .refresh-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background-color: var(--success-color, #28a745);
    color: white;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .refresh-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(40, 167, 69, 0.2);
  }
  
  .refresh-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  .last-updated {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    color: var(--secondary-color, #6c757d);
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
  }
  
  .billing-filters {
    margin-bottom: 1.5rem;
  }
  
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-width: 200px;
  }
  
  .search-group {
    flex: 2;
  }
  
  .filter-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    color: var(--text-color, #495057);
  }
  
  .partner-select {
    width: 100%;
  }
  
  .search-container {
    display: flex;
    width: 100%;
  }
  
  .search-input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top-left-radius: var(--border-radius, 10px);
    border-bottom-left-radius: var(--border-radius, 10px);
    border-right: none;
    background-color: var(--light-color, #f8f9fa);
    transition: var(--transition, all 0.3s ease);
    font-size: 0.9rem;
    color: var(--text-color, #495057);
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--primary-color, #4a6cf7);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .search-button {
    padding: 0.75rem 1rem;
    background-color: var(--primary-color, #4a6cf7);
    color: white;
    border: none;
    border-top-right-radius: var(--border-radius, 10px);
    border-bottom-right-radius: var(--border-radius, 10px);
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-button:hover {
    background-color: #3a5bd8;
  }
  
  .billing-data {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .billing-summary {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
  
  .summary-card {
    flex: 1;
    min-width: 200px;
    background-color: var(--primary-light, #e8effd);
    padding: 1.25rem;
    border-radius: var(--border-radius, 10px);
    display: flex;
    flex-direction: column;
  }
  
  .summary-title {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--primary-color, #4a6cf7);
    margin-bottom: 0.5rem;
  }
  
  .summary-value {
    font-size: 1.75rem;
    font-weight: 700;
    /* color: var(--dark-color, #343a40); */
  }
  
  .billing-table-container {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .billing-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.9rem;
  }
  
  .billing-table th {
    background-color: var(--light-color, #f8f9fa);
    color: var(--dark-color, #343a40);
    font-weight: 600;
    text-align: left;
    padding: 1rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .billing-table th:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  .th-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .sort-icon {
    margin-left: 0.5rem;
    font-size: 0.85rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .billing-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    vertical-align: middle;
  }
  
  .data-row {
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .data-row:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  .amount-cell {
    font-weight: 600;
    text-align: right;
    color: var(--primary-color, #4a6cf7);
    font-family: 'Courier New', monospace;
  }
  
  .no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
    color: var(--secondary-color, #6c757d);
    font-style: italic;
    border: 1px dashed rgba(0, 0, 0, 0.1);
  }
  
  .message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--warning-color, #ffc107);
    color: white;
    font-weight: 700;
    font-size: 1.25rem;
  }
  
  /* Animation for the spinner */
  .spinner {
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .billing-header {
      flex-direction: column;
      gap: 1rem;
    }
    
    .billing-actions {
      align-self: flex-start;
    }
    
    .filter-container {
      flex-direction: column;
      gap: 1rem;
    }
    
    .filter-group {
      width: 100%;
    }
  }
  
  .button-icon {
    margin-right: 5px;
  }
  
  .refresh-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    background-color: #f8f9fa;
    color: #333;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .refresh-button:hover {
    background-color: #e9ecef;
    border-color: rgba(0, 0, 0, 0.2);
  }
  
  .refresh-button:active {
    background-color: #dee2e6;
    transform: translateY(1px);
  }
  
  .refresh-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  