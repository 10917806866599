/* InvoicePayment.css */

.invoice-payment-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

.invoice-payment-header {
  margin-bottom: 2rem;
  text-align: center;
}

.invoice-payment-header h2 {
  font-size: 1.8rem;
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.invoice-payment-header p {
  color: #718096;
  font-size: 1rem;
}

.invoice-payment-error {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
  background-color: #FEF2F2;
  border-left: 4px solid #EF4444;
  border-radius: 6px;
  color: #B91C1C;
}

.invoice-payment-error svg {
  margin-right: 0.75rem;
  flex-shrink: 0;
}

/* Form Styling */
.invoice-payment-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.invoice-payment-form-group {
  display: flex;
  flex-direction: column;
}

.invoice-payment-form-group label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4B5563;
  margin-bottom: 0.5rem;
}

/* Search Styling */
.search-group {
  position: relative;
}

.search-container {
  position: relative;
  width: 100%;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-icon, .currency-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6B7280;
}

.invoice-search-input {
  width: 100%;
  padding: 1rem 1rem 1rem 2.5rem;
  font-size: 1rem;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  background-color: #F9FAFB;
  transition: all 0.3s ease;
}

.invoice-search-input:focus {
  outline: none;
  border-color: #4F46E5;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
}

.invoice-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.25rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
  border: 1px solid #E5E7EB;
}

.invoice-result-item {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #F3F4F6;
  transition: background-color 0.2s ease;
}

.invoice-result-item:last-child {
  border-bottom: none;
}

.invoice-result-item:hover {
  background-color: #F9FAFB;
}

.invoice-result-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.invoice-number {
  font-weight: 600;
  color: #1F2937;
}

.invoice-date {
  color: #6B7280;
  font-size: 0.9rem;
}

.invoice-result-amounts {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #4B5563;
}

.no-results {
  padding: 1rem;
  text-align: center;
  color: #6B7280;
}

/* Selected Invoice */
.selected-invoice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #F0F9FF;
  border: 1px solid #BAE6FD;
  border-radius: 8px;
}

.selected-invoice-details {
  flex: 1;
}

.selected-invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.selected-invoice-amounts {
  display: flex;
  justify-content: space-between;
}

.amount-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.amount-label {
  font-size: 0.9rem;
  color: #4B5563;
}

.amount-value {
  font-weight: 600;
  color: #1F2937;
}

.amount-value.remaining {
  color: #0369A1;
}

.clear-invoice-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  color: #6B7280;
  transition: all 0.2s ease;
}

.clear-invoice-btn:hover {
  background-color: #E5E7EB;
  color: #1F2937;
}

/* Payment Amount */
.payment-input-wrapper {
  position: relative;
  width: 100%;
}

.invoice-payment-input {
  width: 100%;
  padding: 1rem 1rem 1rem 2.5rem;
  font-size: 1rem;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  background-color: #F9FAFB;
  transition: all 0.3s ease;
}

.invoice-payment-input:focus {
  outline: none;
  border-color: #4F46E5;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
}

.invoice-payment-input:disabled {
  background-color: #F3F4F6;
  cursor: not-allowed;
}

.payment-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.invoice-payment-hint {
  color: #6B7280;
  font-size: 0.9rem;
}

.max-amount-btn {
  background-color: transparent;
  border: none;
  color: #4F46E5;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.max-amount-btn:hover {
  background-color: #EEF2FF;
}

.payment-error {
  color: #DC2626;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Submit Button */
.invoice-payment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
  background-color: #4F46E5;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.invoice-payment-button:hover:not(:disabled) {
  background-color: #4338CA;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3);
}

.invoice-payment-button:disabled {
  background-color: #E5E7EB;
  color: #9CA3AF;
  cursor: not-allowed;
}

/* Loading State */
.invoice-payment-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  gap: 1.5rem;
}

.loading-icon {
  color: #4F46E5;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.95);
  }
}
