/* ResetPassword.css */
:root {
    --reset-primary: #4a6cf7;
    --reset-primary-light: #e8effd;
    --reset-secondary: #6c757d;
    --reset-danger: #dc3545;
    --reset-success: #28a745;
    --reset-warning: #ffc107;
    --reset-info: #17a2b8;
    --reset-dark: #343a40;
    --reset-light: #f8f9fa;
    --reset-white: #ffffff;
    --reset-border: rgba(0, 0, 0, 0.1);
    --reset-radius: 12px;
    --reset-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    --reset-transition: all 0.2s ease-in-out;
  }
  
  /* Main container */
  .reset-password-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--reset-light);
    padding: 2rem 1rem;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  }
  
  .reset-password-container {
    max-width: 520px;
    width: 100%;
  }
  
  /* Logo */
  .reset-logo-container {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .reset-logo {
    height: 60px;
    width: auto;
  }
  
  /* Main card */
  .reset-card {
    background-color: var(--reset-white);
    border-radius: var(--reset-radius);
    box-shadow: var(--reset-shadow);
    padding: 2rem;
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  /* Back to login link */
  .back-to-login {
    display: inline-flex;
    align-items: center;
    color: var(--reset-secondary);
    font-size: 0.875rem;
    text-decoration: none;
    margin-bottom: 1.5rem;
    transition: var(--reset-transition);
  }
  
  .back-to-login svg {
    margin-right: 0.5rem;
  }
  
  .back-to-login:hover {
    color: var(--reset-primary);
  }
  
  /* Headings */
  .reset-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--reset-dark);
    margin: 0 0 0.5rem 0;
  }
  
  .reset-description {
    color: var(--reset-secondary);
    font-size: 0.95rem;
    margin: 0 0 1.5rem 0;
  }
  
  /* Form */
  .reset-form {
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--reset-dark);
  }
  
  .input-icon {
    margin-right: 0.5rem;
    color: var(--reset-primary);
  }
  
  /* Password input with toggle */
  .password-input-container {
    position: relative;
  }
  
  .password-input {
    width: 100%;
    padding: 0.875rem 3rem 0.875rem 1rem;
    border: 1px solid var(--reset-border);
    border-radius: var(--reset-radius);
    font-size: 0.95rem;
    transition: var(--reset-transition);
  }
  
  .password-input:focus {
    outline: none;
    border-color: var(--reset-primary);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.15);
  }
  
  .password-input::placeholder {
    color: #adb5bd;
  }
  
  .password-input.input-error {
    border-color: var(--reset-danger);
  }
  
  .password-input.input-error:focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.15);
  }
  
  .password-input:disabled {
    background-color: var(--reset-light);
    cursor: not-allowed;
  }
  
  .password-toggle {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--reset-secondary);
    font-size: 1.1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .password-toggle:hover {
    color: var(--reset-primary);
  }
  
  /* Error message */
  .error-message {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    color: var(--reset-danger);
    font-size: 0.85rem;
  }
  
  .error-icon {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
  
  /* Password strength meter */
  .password-strength {
    margin-top: 1rem;
  }
  
  .strength-label {
    font-size: 0.75rem;
    color: var(--reset-secondary);
    margin-bottom: 0.25rem;
  }
  
  .strength-meter {
    height: 4px;
    background-color: #e2e8f0;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 0.25rem;
  }
  
  .strength-bar {
    height: 100%;
    border-radius: 2px;
    transition: width 0.3s ease, background-color 0.3s ease;
  }
  
  .strength-text {
    font-size: 0.75rem;
    font-weight: 500;
    text-align: right;
  }
  
  /* Password requirements */
  .password-requirements {
    background-color: var(--reset-light);
    border-radius: var(--reset-radius);
    padding: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .requirements-title {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0 0 0.75rem 0;
    color: var(--reset-dark);
  }
  
  .requirements-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .requirements-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: var(--reset-secondary);
    font-size: 0.85rem;
    transition: var(--reset-transition);
  }
  
  .requirements-list li:last-child {
    margin-bottom: 0;
  }
  
  .requirement-icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
  
  .requirement-met {
    color: var(--reset-success);
  }
  
  /* Reset button */
  .reset-button {
    width: 100%;
    padding: 0.875rem;
    background-color: var(--reset-primary);
    color: white;
    border: none;
    border-radius: var(--reset-radius);
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: var(--reset-transition);
  }
  
  .reset-button:hover:not(:disabled) {
    background-color: #3a5ad9;
    box-shadow: 0 4px 10px rgba(74, 108, 247, 0.2);
  }
  
  .reset-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Footer */
  .reset-footer {
    text-align: center;
    color: var(--reset-secondary);
    font-size: 0.875rem;
  }
  
  .support-link {
    color: var(--reset-primary);
    text-decoration: none;
    font-weight: 500;
    transition: var(--reset-transition);
  }
  
  .support-link:hover {
    text-decoration: underline;
  }
  