/* AccessDenied.css */

.access-denied-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f7f9fc;
    background-image: linear-gradient(
      135deg,
      rgba(245, 247, 250, 0.8) 0%,
      rgba(237, 242, 247, 0.8) 100%
    );
  }
  
  .access-denied-card {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    position: relative;
  }
  
  .access-denied-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2.5rem 0;
    background-color: #fed7d7;
    background-image: linear-gradient(
      to right,
      #fed7d7,
      #fbd5d5
    );
    color: #e53e3e;
    position: relative;
  }
  
  .access-denied-icon::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 40px;
    background-color: white;
    border-radius: 50% 50% 0 0;
  }
  
  .access-denied-content {
    padding: 2rem;
    width: 100%;
    text-align: center;
  }
  
  .access-denied-title {
    color: #2d3748;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  
  .access-denied-message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    margin-bottom: 1.2rem;
    padding: 0.8rem 1.5rem;
    background-color: #fff5f5;
    border-radius: 8px;
    color: #c53030;
    font-weight: 500;
  }
  
  .alert-icon {
    flex-shrink: 0;
  }
  
  .access-denied-description {
    color: #4a5568;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .access-denied-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .login-button-a,
  .home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.875rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
  }
  
  .login-button-a {
    background-color: #4299e1;
    color: white;
  }
  
  .login-button-a:hover {
    background-color: #3182ce;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(66, 153, 225, 0.3);
  }
  
  .home-button {
    background-color: transparent;
    color: #4a5568;
    border: 1px solid #e2e8f0;
  }
  
  .home-button:hover {
    background-color: #f7fafc;
    border-color: #cbd5e0;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .access-denied-card {
    animation: fadeIn 0.4s ease-out;
  }
  