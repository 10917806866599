/* Admin Logs Styles */
:root {
    --primary-color: #4a6cf7;
    --primary-light: #e8effd;
    --secondary-color: #6c757d;
    --background-color: #f5f7fb;
    --card-bg: #ffffff;
    --border-radius: 10px;
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
  }
  
  .admin-logs-container {
    max-width: 1200px;
    margin: 2rem auto;
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
  }
  
  .admin-logs-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    background-color: var(--primary-light);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .admin-logs-header .header-icon {
    color: var(--primary-color);
    margin-right: 1rem;
    font-size: 2rem;
  }
  
  .admin-logs-header h2 {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 700;
  }
  
  .admin-logs-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 600px;
  }
  
  .logs-sidebar {
    background-color: var(--background-color);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
  }
  
  .logs-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .logs-sidebar-header h3 {
    color: var(--secondary-color);
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .logs-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .logs-list-item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    cursor: pointer;
    border-radius: 6px;
    transition: var(--transition);
    margin-bottom: 0.5rem;
  }
  
  .logs-list-item:hover {
    background-color: var(--primary-light);
  }
  
  .logs-list-item.selected {
    background-color: var(--primary-color);
    color: white;
  }
  
  .logs-list-item .file-icon {
    margin-right: 0.75rem;
    color: var(--primary-color);
  }
  
  .logs-list-item.selected .file-icon {
    color: white;
  }
  
  .logs-viewer {
    padding: 1.5rem;
    background-color: white;
  }
  
  .logs-viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .logs-viewer-header h3 {
    color: var(--secondary-color);
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .logs-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    color: var(--secondary-color);
  }
  
  .logs-empty-state .empty-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0.3;
  }
  
  .logs-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--primary-color);
  }
  
  .loading-icon {
    animation: spin 1s linear infinite;
    margin-right: 0.5rem;
  }
  
  .logs-editor {
    height: 100%;
    overflow: auto;
  }
  
  .code-editor {
    min-height: 500px;
    background-color: var(--background-color) !important;
    border-radius: 6px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  