/* ClientForm.css */

.client-form-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  /* Loading Container */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    color: var(--secondary-color, #6c757d);
  }
  
  .loading-container .spinner {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--primary-color, #4a6cf7);
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Form Header */
  .client-form-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .form-title-section {
    flex: 1;
  }
  
  .form-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .form-icon {
    font-size: 2rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .form-title h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .form-subtitle {
    color: var(--secondary-color, #6c757d);
    font-size: 1rem;
    margin: 0;
  }
  
  /* Form Layout */
  .client-form {
    margin-bottom: 2rem;
  }
  
  .form-sections {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .form-section {
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .section-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: var(--dark-color, #343a40);
    padding-bottom: 0.75rem;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  }
  
  .section-icon {
    color: var(--primary-color, #4a6cf7);
    font-size: 1.25rem;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .form-row:last-child {
    margin-bottom: 0;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .full-width {
    grid-column: 1 / -1;
  }
  
  /* Form Controls */
  .form-label {
    display: flex!important;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    color: var(--dark-color, #343a40);
    font-size: 0.95rem;
  }
  
  .form-label svg {
    color: var(--primary-color, #4a6cf7);
    font-size: 1rem;
  }
  
  .required-indicator {
    color: var(--danger-color, #dc3545);
    margin-left: 0.25rem;
  }
  
  .form-control, .form-select {
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius, 10px);
    font-size: 0.95rem;
    transition: var(--transition, all 0.3s ease);
    width: 100%;
  }
  
  .form-control:focus, .form-select:focus {
    outline: none;
    border-color: var(--primary-color, #4a6cf7);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .form-control.is-invalid, .form-select.is-invalid {
    border-color: var(--danger-color, #dc3545);
  }
  
  .error-message {
    color: var(--danger-color, #dc3545);
    font-size: 0.85rem;
    margin-top: 0.25rem;
  }
  
  /* Form Actions */
  .form-actions {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .submit-button, .delete-button, .cancel-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    font-size: 0.95rem;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .submit-button {
    background-color: var(--primary-color, #4a6cf7);
    color: white;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #3a5bd8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(74, 108, 247, 0.2);
  }
  
  .delete-button {
    background-color: var(--danger-color, #dc3545);
    color: white;
  }
  
  .delete-button:hover:not(:disabled) {
    background-color: #c82333;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(220, 53, 69, 0.2);
  }
  
  .cancel-button {
    background-color: var(--secondary-color, #6c757d);
    color: white;
  }
  
  .cancel-button:hover:not(:disabled) {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(108, 117, 125, 0.2);
  }
  
  .submit-button:disabled,
  .delete-button:disabled,
  .cancel-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  