/* ErrorPage.css */

.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--body-bg, #f5f7fb);
    padding: 2rem;
  }
  
  .error-card {
    max-width: 550px;
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 3rem 2rem;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.5s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .error-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .error-icon {
    font-size: 5rem;
    color: var(--warning-color, #ffc107);
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.1); opacity: 0.7; }
    100% { transform: scale(1); opacity: 1; }
  }
  
  .error-title {
    font-size: 5rem;
    font-weight: 700;
    color: var(--danger-color, #dc3545);
    margin: 0 0 1rem 0;
    line-height: 1;
  }
  
  .error-subtitle {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--dark-color, #343a40);
    margin-bottom: 1.5rem;
  }
  
  .error-message {
    font-size: 1.1rem;
    color: var(--secondary-color, #6c757d);
    margin-bottom: 2rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
  }
  
  .error-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .error-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    font-size: 0.95rem;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    text-decoration: none;
  }
  
  .back-button {
    background-color: var(--secondary-color, #6c757d);
    color: white;
  }
  
  .back-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(108, 117, 125, 0.2);
  }
  
  .home-button {
    background-color: var(--primary-color, #4a6cf7);
    color: white;
  }
  
  .home-button:hover {
    background-color: #3a5bd8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(74, 108, 247, 0.2);
  }
  
  .refresh-button {
    background-color: var(--info-color, #17a2b8);
    color: white;
  }
  
  .refresh-button:hover {
    background-color: #138496;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(23, 162, 184, 0.2);
  }
  
