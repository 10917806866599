.reports-page {
    padding: 20px;
  }
  
  .report-select {
    margin-bottom: 20px;
  }
  
  .report-table-container {
    margin-top: 20px;
  }
  
  .report-title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .report-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .report-table th,
  .report-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .report-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .report-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .report-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .text-right {
    text-align: right;
  }

  @media print {
    .print {
      display: none !important;
    }
  }

  /* ReportsPage.css */
/* Modern design for reports page that complements dashboard styles */

.reports-container {
  /* background-color: var(--card-bg, #ffffff); */
  border-radius: var(--border-radius, 10px);
  /* box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05)); */
  padding: 2rem;
  margin-bottom: 2rem;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
}

.reports-header {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 1rem;
}

.reports-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--dark-color, #343a40);
  margin-bottom: 0.5rem;
}

.reports-subtitle {
  color: var(--secondary-color, #6c757d);
  font-size: 0.9rem;
}

.reports-form {
  gap: 1.5rem;
  margin-bottom: 2rem;
}


.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-color, #495057);
}

.form-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius, 10px);
  background-color: var(--light-color, #f8f9fa);
  transition: var(--transition, all 0.3s ease);
  font-size: 0.9rem;
  color: var(--text-color, #495057);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236c757d' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
}

.form-select:focus {
  outline: none;
  border-color: var(--primary-color, #4a6cf7);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
}

.form-select option {
  padding: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius, 10px);
  background-color: var(--light-color, #f8f9fa);
  transition: var(--transition, all 0.3s ease);
  font-size: 0.9rem;
  color: var(--text-color, #495057);
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color, #4a6cf7);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
}

.date-range-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.date-input-group {
  flex: 1;
  min-width: 200px;
}

.reports-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.btn-report {
  background-color: var(--primary-color, #4a6cf7);
  color: white;
  border: none;
  border-radius: var(--border-radius, 10px);
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.btn-report:hover {
  background-color: rgba(74, 108, 247, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-report:disabled {
  background-color: var(--secondary-color, #6c757d);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.btn-print {
  background-color: var(--success-color, #28a745);
  color: white;
}

.btn-print:hover {
  background-color: rgba(40, 167, 69, 0.9);
}

.loading-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--secondary-color, #6c757d);
  font-style: italic;
}

/* Report Table Styles */
.report-result {
  background-color: var(--card-bg, #ffffff);
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.report-heading {
  background-color: var(--primary-light, #e8effd);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  flex-direction: row; /* Force horizontal layout */
  flex-wrap: nowrap; /* Prevent wrapping */
  width: 100%; /* Ensure full width */
}

.report-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-color, #4a6cf7);
}

.report-date {
  font-size: 0.875rem;
  color: var(--secondary-color, #6c757d);
}

/* Print styles */
@media print {
  .element-to-hide {
    display: none !important;
  }
  
  /* body, .reports-container {
    background-color: white !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
  }
  
  .report-result {
    border: none !important;
    box-shadow: none !important;
  }
  
  .report-heading {
    background-color: white !important;
    border-bottom: 2px solid #000 !important;
    padding-bottom: 0.5rem !important;
  }
  
  table {
    width: 100% !important;
    border-collapse: collapse !important;
  }
  
  th, td {
    border: 1px solid #ddd !important;
    padding: 0.5rem !important;
  } */
}

/* Add these to your ReportsPage.css */

/* Report Table Styles */
.report-table-wrapper {
  padding: 1rem;
  overflow-x: auto;
}

.report-table-container {
  width: 100%;
}

.report-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.9rem;
}

.report-table th {
  background-color: var(--light-color, #f8f9fa);
  color: var(--dark-color, #343a40);
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
}

.report-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  vertical-align: middle;
}

.report-table tbody tr:hover {
  background-color: var(--primary-light, #e8effd);
}

.currency-cell {
  text-align: right;
  font-family: 'Courier New', monospace;
  font-weight: 600;
}

.report-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

/* For totals or summary rows */
.report-table tr.summary-row {
  background-color: var(--primary-light, #e8effd);
  font-weight: 600;
}

.no-data {
  padding: 2rem;
  text-align: center;
  color: var(--secondary-color, #6c757d);
  font-style: italic;
}

/* Spinner animation for loading states */
.spinner {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Extra table styles for different report types */
.aging-report td:nth-child(n+2) {
  text-align: right;
}

.journal-report td:nth-child(n+3):nth-child(-n+5) {
  text-align: right;
}


.report-table th {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;
}

.report-table th:hover {
  background-color: var(--primary-light, #e8effd);
}

.report-table th.sort-active {
  background-color: var(--primary-light, #e8effd);
  color: var(--primary-color, #4a6cf7);
}

.th-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort-icon {
  margin-left: 8px;
  font-size: 0.9rem;
}

/* Cursors */
.report-table th {
  cursor: pointer;
}

.report-table th:hover .th-content::after {
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px solid var(--secondary-color, #6c757d);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  opacity: 0.4;
}

/* ReportsPage.css */

/* Main container */
.reports-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

/* Header section */
.reports-header {
  margin-bottom: 2rem;
}

.reports-title-section {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.reports-header-icon {
  font-size: 1.75rem;
  color: var(--primary-color, #4a6cf7);
  margin-right: 1rem;
}

.reports-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--dark-color, #343a40);
  margin: 0;
}

.reports-subtitle {
  color: var(--secondary-color, #6c757d);
  font-size: 1rem;
  margin: 0;
}

/* Report selection container */
.reports-selection-container {
  background-color: var(--white, #ffffff);
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  overflow: hidden;
}

/* Report type selector */
.reports-type-selector {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.selector-title {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1.25rem;
  color: var(--dark-color, #343a40);
}

.selector-title svg {
  margin-right: 0.5rem;
  color: var(--primary-color, #4a6cf7);
}

/* Report cards grid */
.report-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 1rem;
}

.report-card {
  background-color: var(--light-color, #f8f9fa);
  border-radius: 0.75rem;
  padding: 1.25rem;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  position: relative;
}

.report-card:hover {
  background-color: rgba(0, 0, 0, 0.02);
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.report-card.selected {
  background-color: rgba(0, 0, 0, 0.02);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.report-card-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.report-card-content {
  flex: 1;
}

.report-card-title {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: var(--dark-color, #343a40);
}

.report-card-description {
  font-size: 0.85rem;
  color: var(--secondary-color, #6c757d);
  margin: 0;
  line-height: 1.4;
}

.report-card-check {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.25rem;
}

/* Date selector */
.reports-date-selector {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.date-quick-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.date-quick-filter {
  background-color: var(--light-color, #f8f9fa);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--dark-color, #343a40);
}

.date-quick-filter:hover {
  background-color: var(--primary-light, #e8effd);
  border-color: var(--primary-color, #4a6cf7);
  color: var(--primary-color, #4a6cf7);
}

.date-quick-filter svg {
  margin-right: 0.5rem;
}

.date-custom-range {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
}

.date-input-group {
  flex: 1;
  min-width: 180px;
}

.date-label {
  display: block;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--dark-color, #343a40);
}

.date-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.date-input:focus {
  outline: none;
  border-color: var(--primary-color, #4a6cf7);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
}

.date-range-separator {
  display: flex;
  align-items: center;
  color: var(--secondary-color, #6c757d);
  padding-bottom: 0.75rem;
}

/* Info banner */
.reports-info-banner {
  padding: 1.25rem 1.5rem;
  background-color: var(--primary-light, #e8effd);
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.info-icon {
  font-size: 1.25rem;
  color: var(--primary-color, #4a6cf7);
  margin-right: 1rem;
  flex-shrink: 0;
}

.info-text {
  margin: 0;
  font-size: 0.95rem;
  color: var(--primary-color, #4a6cf7);
}

/* Actions container */
.reports-actions-container {
  padding: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.reports-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  min-width: 160px;
}

.button-icon {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.generate-button {
  background-color: var(--primary-color, #4a6cf7);
  color: white;
}

.generate-button:hover:not(:disabled) {
  background-color: #3a5ad9;
  box-shadow: 0 4px 10px rgba(74, 108, 247, 0.2);
}

.generate-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.print-button {
  background-color: var(--success-color, #28a745);
  color: white;
}

.print-button:hover {
  background-color: #218838;
  box-shadow: 0 4px 10px rgba(40, 167, 69, 0.2);
}

/* Loading indicator */
.reports-loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  background-color: var(--light-color, #f8f9fa);
  border-radius: 0.75rem;
  margin-bottom: 2rem;
}

.loading-icon {
  font-size: 2rem;
  color: var(--primary-color, #4a6cf7);
  margin-bottom: 1rem;
}

.loading-text {
  color: var(--secondary-color, #6c757d);
  margin: 0;
}

/* Report result */
.report-result {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 2rem;
}

.report-heading {
  background-color: var(--primary-light, #e8effd);
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.report-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-color, #4a6cf7);
  display: flex;
  align-items: center;
}

.report-date {
  font-size: 0.875rem;
  color: var(--secondary-color, #6c757d);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

/* Spinner animation */
.spinner {
  animation: spin 1.5s linear infinite;
}

/* AR Ledger Button Section */
.reports-ledger-link {
  background-color: #eef2ff;
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-left: 4px solid #3730a3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports-ledger-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background-color: #4f46e5;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  max-width: 400px;
}

.reports-ledger-button:hover {
  background-color: #4338ca;
}

.ledger-button-icon {
  font-size: 1.25rem;
}

.ledger-button-icon-right {
  font-size: 0.875rem;
  opacity: 0.7;
}

.ledger-description {
  margin-top: 0.75rem;
  color: #6b7280;
  font-size: 0.875rem;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .reports-ledger-button {
    width: 100%;
    max-width: none;
  }
}

@media print {
  .reports-ledger-link {
    display: none;
  }
}

/* Add these styles to your existing CSS that contains report table styles */

/* General table styles */
.report-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Important for controlling column widths */
}

.report-table th,
.report-table td {
  padding: 0.75rem 0.5rem;
  border: 1px solid #e2e8f0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevent text wrapping */
}

.report-table th {
  background-color: #f8fafc;
  font-weight: 600;
  color: #475569;
}

.report-table tr:nth-child(even) {
  background-color: #f8fafc;
}

.currency-cell {
  text-align: right;
}

/* Print-specific styles */
@media print {
  .report-table-container {
    width: 100%;
    overflow-x: visible !important;
  }
  
  .report-table {
    width: 100% !important;
    table-layout: fixed !important;
    page-break-inside: auto;
  }
  
  .report-table th,
  .report-table td {
    padding: 0.25rem !important; /* Reduced padding for print */
    font-size: 0.8rem !important; /* Smaller font for print */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 100% !important;
  }
  
  .report-table th {
    background-color: #f0f0f0 !important; /* Printable background */
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  
  /* Hide sort icons when printing */
  .sort-icon {
    display: none !important;
  }
  
  /* Ensure the table fits on a single page width */
  .report-table-wrapper {
    page-break-inside: avoid;
    width: 100% !important;
    max-width: 100% !important;
  }
  
  /* Smaller font for header content */
  .th-content {
    font-size: 0.8rem !important;
    font-weight: bold !important;
  }
  
  /* Force columns to be single line with ellipsis */
  .report-table td {
    max-width: 1px !important; /* This forces the text-overflow to work */
  }
}