/* PaymentsForm.css */
:root {
  --payments-primary: #4a6cf7;
  --payments-primary-light: #e8effd;
  --payments-secondary: #6c757d;
  --payments-success: #28a745;
  --payments-warning: #ffc107;
  --payments-danger: #dc3545;
  --payments-light: #f8f9fa;
  --payments-dark: #343a40;
  --payments-white: #ffffff;
  --payments-border: rgba(0, 0, 0, 0.1);
  --payments-radius: 12px;
  --payments-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

/* Page Container */
.payments-page-container {
  padding: 1.5rem;
  max-width: 900px;
  margin: 0 auto;
}

/* Main Card */
.payments-card {
  background-color: var(--payments-white);
  border-radius: var(--payments-radius);
  box-shadow: var(--payments-shadow);
  overflow: hidden;
  margin-bottom: 2rem;
}

/* Header */
.payments-header {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--payments-border);
  background-color: var(--payments-primary-light);
}

.payments-back-button {
  background: none;
  border: none;
  color: var(--payments-primary);
  font-size: 1.25rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.payments-back-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.payments-back-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.payments-title-section {
  flex: 1;
}

.payments-title {
  display: flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--payments-dark);
}

.payments-title-icon {
  margin-right: 0.5rem;
  color: var(--payments-primary);
}

.payments-subtitle {
  margin: 0;
  color: var(--payments-secondary);
  font-size: 0.9rem;
}

/* Invoice Summary Card */
.invoice-summary-card {
  margin: 1.5rem;
  border-radius: var(--payments-radius);
  background-color: var(--payments-light);
  overflow: hidden;
}

.invoice-summary-header {
  padding: 1rem 1.5rem;
  background-color: var(--payments-primary-light);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--payments-border);
}

.invoice-summary-icon {
  margin-right: 0.75rem;
  color: var(--payments-primary);
}

.invoice-summary-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--payments-dark);
}

.invoice-summary-content {
  padding: 1.25rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.25rem;
}

.invoice-summary-item {
  display: flex;
  flex-direction: column;
}

.summary-item-label {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--payments-secondary);
  margin-bottom: 0.5rem;
}

.summary-item-label svg {
  margin-right: 0.5rem;
}

.summary-item-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--payments-dark);
}

.client-name {
  font-weight: 500;
}

.invoice-total {
  color: var(--payments-primary);
}

.payment-status-badge {
  display: inline-block;
  padding: 0.35rem 0.75rem;
  border-radius: 20px;
  color: white;
  font-size: 0.85rem;
  font-weight: 600;
}

/* Form */
.payments-form {
  padding: 1.5rem;
}

.form-content {
  margin-bottom: 2rem;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--payments-dark);
}

.form-icon {
  margin-right: 0.5rem;
  color: var(--payments-primary);
}

.form-control {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--payments-border);
  border-radius: var(--payments-radius);
  font-size: 1rem;
  transition: all 0.2s ease;
}

.form-control:focus {
  outline: none;
  border-color: var(--payments-primary);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
}

.form-control:disabled {
  background-color: var(--payments-light);
  cursor: not-allowed;
}

/* Payment Amount Input */
.payment-amount-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.currency-symbol {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--payments-secondary);
  font-weight: 500;
}

.payment-amount-input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2rem;
  border: 1px solid var(--payments-border);
  border-radius: var(--payments-radius);
  font-size: 1rem;
  transition: all 0.2s ease;
}

.payment-amount-input:focus {
  outline: none;
  border-color: var(--payments-primary);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
}

.payment-amount-input.input-error {
  border-color: var(--payments-danger);
}

.payment-amount-input.input-error:focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
}

/* Form Helpers */
.form-hint {
  font-size: 0.85rem;
  color: var(--payments-secondary);
  margin-top: 0.5rem;
}

.form-error {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--payments-danger);
  font-size: 0.85rem;
}

.error-icon {
  margin-right: 0.5rem;
  flex-shrink: 0;
}

/* Info Box */
.form-info-box {
  background-color: var(--payments-primary-light);
  border-radius: var(--payments-radius);
  padding: 1.25rem;
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.info-icon {
  color: var(--payments-primary);
  margin-right: 1rem;
  margin-top: 0.2rem;
  flex-shrink: 0;
  font-size: 1.1rem;
}

.info-content p {
  margin: 0;
  color: var(--payments-dark);
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Form Actions */
.form-actions {
  border-top: 1px solid var(--payments-border);
  padding-top: 1.5rem;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.submit-button,
.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border-radius: var(--payments-radius);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.button-icon {
  margin-right: 0.5rem;
}

.submit-button {
  background-color: var(--payments-primary);
  color: white;
}

.submit-button:hover:not(:disabled) {
  background-color: #3a5ad9;
  box-shadow: 0 4px 10px rgba(74, 108, 247, 0.2);
}

.delete-button {
  background-color: white;
  color: var(--payments-danger);
  border: 1px solid var(--payments-danger);
}

.delete-button:hover:not(:disabled) {
  background-color: #fff1f0;
}

.submit-button:disabled,
.delete-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Loading Container */
.payments-loading-container {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payments-loading-content {
  text-align: center;
  padding: 2rem;
}

.payments-loading-spinner {
  font-size: 2.5rem;
  color: var(--payments-primary);
  margin-bottom: 1rem;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
