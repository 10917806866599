/* UserForm.css */
:root {
    --form-primary: #4a6cf7;
    --form-primary-light: #e8effd;
    --form-secondary: #6c757d;
    --form-danger: #dc3545;
    --form-success: #28a745;
    --form-warning: #ffc107;
    --form-dark: #343a40;
    --form-light: #f8f9fa;
    --form-white: #ffffff;
    --form-gray: #adb5bd;
    --form-border: rgba(0, 0, 0, 0.1);
    --form-text: #495057;
    --form-radius: 10px;
    --form-transition: all 0.2s ease-in-out;
    --form-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    --form-error-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
    --form-focus-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .user-form-container {
    padding: 1.5rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .user-form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .back-button {
    background: none;
    border: none;
    color: var(--form-primary);
    font-size: 1.25rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: var(--form-transition);
  }
  
  .back-button:hover {
    background-color: var(--form-primary-light);
  }
  
  .user-form-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--form-dark);
    margin: 0;
  }
  
  .user-form-card {
    background-color: var(--form-white);
    border-radius: var(--form-radius);
    box-shadow: var(--form-shadow);
    padding: 2rem;
    margin-bottom: 2rem;
    border: 1px solid var(--form-border);
  }
  
  .form-sections {
    display: grid;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 768px) {
    .form-sections {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .form-section {
    margin-bottom: 1.5rem;
  }
  
  .section-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--form-dark);
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    /* border-bottom: 1px solid var(--form-border); */
  }
  
  .section-icon {
    margin-right: 0.5rem;
    color: var(--form-primary);
  }
  
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .form-group {
    margin-bottom: 1.25rem;
  }
  
  .form-label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--form-dark);
  }
  
  .form-icon {
    margin-right: 0.5rem;
    color: var(--form-primary);
    font-size: 1rem;
  }
  
  .form-input,
  .form-select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--form-border);
    border-radius: var(--form-radius);
    color: var(--form-text);
    font-size: 0.95rem;
    background-color: var(--form-white);
    transition: var(--form-transition);
  }
  
  .form-input:focus,
  .form-select:focus {
    outline: none;
    border-color: var(--form-primary);
    box-shadow: var(--form-focus-shadow);
  }
  
  .form-input::placeholder {
    color: var(--form-gray);
  }
  
  .form-input:disabled {
    background-color: var(--form-light);
    cursor: not-allowed;
    opacity: 0.8;
  }
  
  .input-error {
    border-color: var(--form-danger);
  }
  
  .input-error:focus {
    box-shadow: var(--form-error-shadow);
  }
  
  .error-message {
    color: var(--form-danger);
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }
  
  /* Billing Rates Section */
  .billing-rates-section {
    grid-column: 1 / -1;
    margin-top: 1rem;
    padding: 1.5rem;
    background-color: var(--form-light);
    border-radius: var(--form-radius);
    border: 1px solid var(--form-border);
  }
  
  .billing-rates-list {
    margin-bottom: 1.5rem;
  }
  
  .billing-rate-header {
    display: grid;
    grid-template-columns: 1fr 1fr 80px;
    gap: 1rem;
    margin-bottom: 0.75rem;
    font-weight: 600;
    color: var(--form-secondary);
    font-size: 0.9rem;
  }
  
  .billing-rate-item {
    display: grid;
    grid-template-columns: 1fr 1fr 80px;
    gap: 1rem;
    margin-bottom: 0.75rem;
    align-items: center;
  }
  
  .rate-action-field {
    display: flex;
    justify-content: center;
  }
  
  .remove-rate-button {
    background-color: var(--form-white);
    border: 1px solid var(--form-danger);
    color: var(--form-danger);
    width: 38px;
    height: 38px;
    border-radius: var(--form-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--form-transition);
  }
  
  .remove-rate-button:hover {
    background-color: var(--form-danger);
    color: var(--form-white);
  }
  
  .remove-rate-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: var(--form-secondary);
    color: var(--form-secondary);
  }
  
  .add-rate-button {
    background-color: var(--form-white);
    border: 1px dashed var(--form-primary);
    color: var(--form-primary);
    padding: 0.75rem 1.25rem;
    border-radius: var(--form-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--form-transition);
    font-weight: 500;
    width: 100%;
  }
  
  .add-rate-button:hover {
    background-color: var(--form-primary-light);
  }
  
  .billing-rates-error {
    margin-bottom: 1rem;
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  
  /* Form Actions
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .cancel-button {
    background-color: var(--form-light);
    border: 1px solid var(--form-border);
    color: var(--form-secondary);
    padding: 0.75rem 1.5rem;
    border-radius: var(--form-radius);
    font-weight: 500;
    cursor: pointer;
    transition: var(--form-transition);
  }
  
  .cancel-button:hover {
    background-color: var(--form-secondary);
    color: var(--form-white);
  }
  
  .submit-button {
    background-color: var(--form-primary);
    border: none;
    color: var(--form-white);
    padding: 0.75rem 1.5rem;
    border-radius: var(--form-radius);
    font-weight: 500;
    cursor: pointer;
    transition: var(--form-transition);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .submit-button:hover {
    background-color: #3a5ad9;
    box-shadow: 0 4px 10px rgba(74, 108, 247, 0.2);
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  } */
  

  .lock-toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .lock-toggle-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border-radius: 4px;
  }
  
  .lock-toggle-button.locked {
    background-color: #ff4d4f;
    color: white;
  }
  
  .lock-toggle-button.unlocked {
    background-color: #52c41a;
    color: white;
  }
  
  .login-attempts-badge {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #faad14;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .login-attempts-list {
    margin-top: 10px;
  }
  
  .login-attempt-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .attempt-status.success {
    color: #52c41a;
  }
  
  .attempt-status.failure {
    color: #ff4d4f;
  }