/* InvoiceForm.css */

.invoice-form-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  }
  
  .invoice-form-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .invoice-form-header h1 {
    font-size: 1.8rem;
    color: #2d3748;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: none;
    background-color: transparent;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 6px;
  }
  
  .back-button:hover {
    background-color: #f7fafc;
    color: #4299e1;
  }
  
  .back-button svg {
    margin-right: 0.5rem;
  }
  
  .client-badge {
    position: absolute;
    right: 0;
    padding: 0.5rem 1rem;
    background-color: #ebf8ff;
    color: #3182ce;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-form-error {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    background-color: #FEF2F2;
    border-left: 4px solid #EF4444;
    border-radius: 6px;
    color: #B91C1C;
  }
  
  .invoice-form-error svg {
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
  
  .invoice-summary {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: #f8fafc;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  
  .invoice-summary-item {
    display: flex;
    flex-direction: column;
    min-width: 150px;
  }
  
  .invoice-summary-item .label {
    font-size: 0.8rem;
    color: #64748b;
    margin-bottom: 0.25rem;
  }
  
  .invoice-summary-item .value {
    font-weight: 600;
    color: #334155;
    font-size: 1.1rem;
  }
  
  .invoice-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: #4B5563;
    margin-bottom: 0.5rem;
  }
  
  .form-group label svg {
    color: #64748b;
  }
  
  .input-wrapper {
    position: relative;
  }
  
  .invoice-form input,
  .invoice-form textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    background-color: #F9FAFB;
    transition: all 0.3s ease;
  }
  
  .invoice-form input:focus,
  .invoice-form textarea:focus {
    outline: none;
    border-color: #4F46E5;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
  }
  
  .invoice-form input.input-error,
  .invoice-form textarea.input-error {
    border-color: #EF4444;
    background-color: #FEF2F2;
  }
  
  .error-message {
    color: #DC2626;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  
  .invoice-form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    gap: 1rem;
  }
  
  .update-button,
  .delete-button,
  .confirm-delete-button,
  .cancel-delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
  }
  
  .update-button {
    background-color: #4F46E5;
    color: white;
    min-width: 180px;
  }
  
  .update-button:hover:not(:disabled) {
    background-color: #4338CA;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3);
  }
  
  .update-button:disabled {
    background-color: #E5E7EB;
    color: #9CA3AF;
    cursor: not-allowed;
  }
  
  .delete-button {
    background-color: #FEF2F2;
    color: #DC2626;
    border: 1px solid #FECACA;
  }
  
  .delete-button:hover:not(:disabled) {
    background-color: #FEE2E2;
    border-color: #DC2626;
  }
  
  .delete-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .delete-confirmation {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .delete-confirmation span {
    font-weight: 500;
    color: #DC2626;
  }
  
  .confirm-delete-button {
    background-color: #DC2626;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  .confirm-delete-button:hover:not(:disabled) {
    background-color: #B91C1C;
  }
  
  .cancel-delete-button {
    background-color: #F3F4F6;
    color: #4B5563;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  .cancel-delete-button:hover:not(:disabled) {
    background-color: #E5E7EB;
  }
  
  /* Loading spinner */
  .invoice-form-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 300px;
    color: #6B7280;
  }
  
  .loading-spinner {
    color: #4F46E5;
    font-size: 2rem;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  