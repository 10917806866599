/* ReportPage.css */

.card.receipt {
    width: 60%;
    margin: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .card-header {
    background-color: #007bff;
    color: white;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .task-list {
    list-style-type: none;
    padding: 0;
  }
  
  .task-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .task-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .task-title {
    font-weight: bold;
  }
  
  .task-status {
    font-size: 14px;
  }
  
  .task-total {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
  }
/*   
  .btn-primary.receipt {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
  }
   */
  .btn-primary {
    background-color: #0d6efd !important;
  }

   /* .element-to-show{
    display: none;
  } */

   @media print {
    .element-to-hide {
      display: none;
    }
  }

    /* .element-to-show{
      display: block;
    } */
  
    /* Add more styles for other elements you want to hide when printing */
