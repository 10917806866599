/* TimeEntries.css */

.time-entries-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Header Styles */
.time-entries-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.header-title-section {
  flex: 1;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.header-icon {
  font-size: 1.75rem;
  color: var(--primary-color, #4a6cf7);
}

.header-title h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--dark-color, #343a40);
  margin: 0;
}

.header-subtitle {
  color: var(--secondary-color, #6c757d);
  font-size: 1rem;
  margin: 0;
}

.user-highlight {
  font-weight: 600;
  color: var(--primary-color, #4a6cf7);
}

.header-actions {
  display: flex;
  gap: 0.75rem;
}

/* Action Buttons */
.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: var(--border-radius, 10px);
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
}

.create-button {
  background-color: var(--success-color, #28a745);
  color: white;
}

.create-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(40, 167, 69, 0.2);
}

.toggle-button {
  background-color: var(--primary-color, #4a6cf7);
  color: white;
}

.toggle-button:hover {
  background-color: #3a5bd8;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(74, 108, 247, 0.2);
}

/* Filters Section */
.time-entries-filters {
  margin-bottom: 1.5rem;
}

.filters-card {
  background-color: var(--card-bg, #ffffff);
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.filters-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.date-filters,
.user-filter,
.search-filter {
  flex: 1;
  min-width: 200px;
}

.filter-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: var(--dark-color, #343a40);
}

/* Date Picker Styling */
.date-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius, 10px);
  background-color: var(--light-color, #f8f9fa);
  font-size: 0.9rem;
}

.date-range-inputs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.date-range-separator {
  color: var(--secondary-color, #6c757d);
  font-weight: 500;
}

/* User Select Styling */
.user-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius, 10px);
  background-color: var(--light-color, #f8f9fa);
  font-size: 0.9rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236c757d' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
}


.search-form {
  display: flex;
  position: relative;
}
/* 

/* .search-input {
  width: 100%;
  padding: 0.75rem;
  padding-right: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius, 10px);
  background-color: var(--light-color, #f8f9fa);
  font-size: 0.9rem;
} */

/* .search-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3rem;
  background-color: var(--primary-color, #4a6cf7);
  color: white;
  border: none;
  border-top-right-radius: var(--border-radius, 10px);
  border-bottom-right-radius: var(--border-radius, 10px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* Filters Actions */
.filters-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.filter-action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: var(--border-radius, 10px);
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
}

.refresh-button {
  background-color: var(--info-color, #17a2b8);
  color: white;
}

.refresh-button:hover:not(:disabled) {
  background-color: #138496;
}

.refresh-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.clear-button {
  background-color: var(--secondary-color, #6c757d);
  color: white;
}

.clear-button:hover {
  background-color: #5a6268;
}

/* Summary Cards */
.time-entries-summary {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.summary-card {
  flex: 1;
  min-width: 200px;
  background-color: var(--card-bg, #ffffff);
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: var(--transition, all 0.3s ease);
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.summary-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--secondary-color, #6c757d);
  margin-bottom: 0.5rem;
}

.summary-value {
  font-size: 1.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.summary-icon {
  font-size: 1.25rem;
}

.hours-value {
  color: var(--primary-color, #4a6cf7);
}

.amount-value {
  color: var(--success-color, #28a745);
}

.entries-value {
  color: var(--info-color, #17a2b8);
}

/* Table Styles */
.time-entries-table-container {
  background-color: var(--card-bg, #ffffff);
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  overflow-x: auto;
}

.time-entries-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.9rem;
}

.time-entries-table th {
  background-color: var(--light-color, #f8f9fa);
  color: var(--dark-color, #343a40);
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
  white-space: nowrap;
}

.time-entries-table th:hover {
  background-color: var(--primary-light, #e8effd);
}

.th-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort-icon {
  margin-left: 0.5rem;
  font-size: 0.85rem;
  color: var(--primary-color, #4a6cf7);
}

.time-entries-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  vertical-align: middle;
}

.entry-row {
  transition: var(--transition, all 0.3s ease);
}

.entry-row:hover {
  background-color: var(--primary-light, #e8effd);
}

.hours-cell {
  font-weight: 600;
  color: var(--primary-color, #4a6cf7);
  text-align: right;
}

.amount-cell {
  font-weight: 600;
  font-family: 'Courier New', monospace;
  color: var(--success-color, #28a745);
  text-align: right;
}

/* Activity Badge */
.activity-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background-color: var(--primary-light, #e8effd);
  color: var(--primary-color, #4a6cf7);
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
}

.activity-icon {
  font-size: 0.85rem;
}

/* Table Action Button */
.table-action-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: var(--border-radius, 10px);
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
}

.edit-button {
  background-color: var(--warning-color, #ffc107);
  color: var(--dark-color, #343a40);
}

.edit-button:hover {
  background-color: #e0a800;
  transform: translateY(-2px);
}

/* Empty State */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
}

.empty-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: var(--secondary-color, #6c757d);
  opacity: 0.2;
}

.empty-state h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--dark-color, #343a40);
}

.empty-state p {
  color: var(--secondary-color, #6c757d);
}

/* Loading Indicator */
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
}

.loading-indicator .spinner {
  animation: spin 1.5s linear infinite;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--primary-color, #4a6cf7);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
