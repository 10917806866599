

.client-container {
    max-width: 1200px;
    margin: 0 auto;
    
}

.client-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.client-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.client-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.client-card-body {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    
}

.client-card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.client-card-text {
    font-size: 1rem;
    margin-bottom: 8px;
}

.client-card-actions {
    display: flex;
    gap: 10px;
    margin-top: auto;
}



.client-management-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .client-management-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .header-title-section {
    flex: 1;
  }
  
  .header-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .header-icon {
    font-size: 1.75rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .header-title h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .header-subtitle {
    color: var(--secondary-color, #6c757d);
    font-size: 1rem;
    margin: 0;
  }
  
  .header-actions {
    display: flex;
    gap: 0.75rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .create-button {
    background-color: var(--success-color, #28a745);
    color: white;
  }
  
  .create-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  .view-toggle-button {
    background-color: var(--primary-color, #4a6cf7);
    color: white;
  }
  
  .view-toggle-button:hover {
    background-color: #3a5bd8;
    transform: translateY(-2px);
  }
  
  .client-management-filters {
    margin-bottom: 1.5rem;
  }
  
   .search-container-cm {
    display: flex;
    gap: 1rem;
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    align-items: center;
  } 
  
  .search-input-group {
    display: flex;
    flex: 1;
    position: relative;
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem;
    padding-right: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius, 10px);
    font-size: 0.9rem;
    transition: var(--transition, all 0.3s ease);
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--primary-color, #4a6cf7);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
   /* .search-button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3rem;
    background-color: var(--primary-color, #4a6cf7);
    color: white;
    border: none;
    border-top-right-radius: var(--border-radius, 10px);
    border-bottom-right-radius: var(--border-radius, 10px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition, all 0.3s ease);
  }  */
  
  .search-button:hover {
    background-color: #3a5bd8;
  }
  
  .refresh-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background-color: var(--light-color, #f8f9fa);
    color: var(--text-color, #495057);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    white-space: nowrap;
  }
  
  .refresh-button:hover:not(:disabled) {
    background-color: var(--primary-light, #e8effd);
    border-color: var(--primary-color, #4a6cf7);
    color: var(--primary-color, #4a6cf7);
  }
  
  .refresh-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .spinner {
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .no-clients-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
    text-align: center;
    color: var(--secondary-color, #6c757d);
    border: 1px dashed rgba(0, 0, 0, 0.1);
  }
  
  .message-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0.5;
  }
  

  .loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: var(--secondary-color, #6c757d);
  }
  
  .loading-indicator .spinner {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--primary-color, #4a6cf7);
  }
  

  .client-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .client-card {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    overflow: hidden;
    transition: var(--transition, all 0.3s ease);
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  
  .client-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-color: var(--primary-color, #4a6cf7);
  }
  
  .client-card-header {
    padding: 1.25rem;
    background-color: var(--primary-light, #e8effd);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .client-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: var(--dark-color, #343a40);
  }
  
  .client-id {
    font-size: 0.875rem;
    color: var(--primary-color, #4a6cf7);
    font-weight: 600;
  }
  
  .client-card-content {
    padding: 1.25rem;
    flex: 1;
  }
  
  .client-info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .client-info-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .info-label {
    font-weight: 600;
    color: var(--secondary-color, #6c757d);
  }
  
  .info-value {
    color: var(--dark-color, #343a40);
  }
  
  .client-card-actions {
    display: flex;
    padding: 1rem;
    background-color: var(--light-color, #f8f9fa);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .card-action-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .card-action-button:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .edit-button {
    background-color: var(--warning-color, #ffc107);
    color: var(--dark-color, #343a40);
  }
  
  .edit-button:hover {
    background-color: #e0a800;
  }
  
  .delete-button-d {
    background-color: var(--danger-color, #dc3545);
    color: white;
  }
  
  .delete-button-d:hover {
    background-color: #c82333;
  }
  

  .client-table-container {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    overflow-x: auto;
  }
  
  .client-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.9rem;
  }
  
  .client-table th {
    background-color: var(--light-color, #f8f9fa);
    color: var(--dark-color, #343a40);
    font-weight: 600;
    text-align: left;
    padding: 1rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .client-table th:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  .th-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .sort-icon {
    margin-left: 0.5rem;
    font-size: 0.85rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .client-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    vertical-align: middle;
  }
  
  .table-row {
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .table-row:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  .actions-cell {
    white-space: nowrap;
  }
  
  .table-action-button-d {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    /* width: 32px; */
    height: 32px;
    border-radius: var(--users-radius);
    cursor: pointer;
    transition: var(--users-transition);
    font-size: 1rem;
  }
  
  