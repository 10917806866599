/* Login.css */
:root {
    --login-primary: #4a6cf7;
    --login-primary-light: #e8effd;
    --login-secondary: #6c757d;
    --login-danger: #dc3545;
    --login-success: #28a745;
    --login-dark: #343a40;
    --login-light: #f8f9fa;
    --login-white: #ffffff;
    --login-text: #495057;
    --login-border-radius: 10px;
    --login-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    --login-transition: all 0.3s ease;
    --login-gradient: linear-gradient(135deg, #4a6cf7, #6c63ff);
  }
  
  .login-page {
    min-height: 100vh;
    background-color: var(--login-light);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  }
  .input-icon-wrapper {
    position: relative;
  }
  
  .password-toggle-button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
  }
  
  .password-toggle-button:hover {
    color: #000;
  }
  /* Navigation */
  .login-nav {
    background-color: var(--login-white);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  .login-nav-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  
  .login-logo {
    display: flex;
    align-items: center;
  }
  
  .login-logo-img {
    height: 40px;
    width: auto;
    margin-right: 0.5rem;
  }
  
  .login-logo-text {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--login-primary);
  }
  
  .contact-button {
    background: none;
    border: 2px solid var(--login-primary);
    color: var(--login-primary);
    padding: 0.5rem 1rem;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    transition: var(--login-transition);
  }
  
  .contact-button:hover {
    background-color: var(--login-primary);
    color: var(--login-white);
  }
  
  /* Hero Section */
  .login-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;
    min-height: calc(100vh - 80px);
  }
  
  .login-hero-content {
    text-align: center;
    max-width: 600px;
  }
  
  .login-title {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--login-dark);
    margin-bottom: 0.5rem;
  }
  
  .login-subtitle {
    font-size: 1.25rem;
    color: var(--login-secondary);
    margin-bottom: 2rem;
    font-style: italic;
  }
  
  .login-logo-container {
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: space-around;
  }
  
  .login-hero-logo {
    width: 180px;
    height: auto;
  }
  
  .login-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--login-gradient);
    color: var(--login-white);
    border: none;
    padding: 1rem 2rem;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(74, 108, 247, 0.2);
    transition: var(--login-transition);
  }
  
  .login-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(74, 108, 247, 0.3);
  }
  
  .login-button-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  
  /* Modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .login-modal {
    background-color: var(--login-white);
    border-radius: var(--login-border-radius);
    box-shadow: var(--login-box-shadow);
    width: 100%;
    max-width: 450px;
    position: relative;
    padding: 2rem;
    animation: modalFadeIn 0.3s ease;
  }
  
  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: var(--login-secondary);
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--login-transition);
  }
  
  .modal-close:hover {
    color: var(--login-danger);
  }
  
  /* Login Form */
  .login-form-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .login-form-header h2 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--login-dark);
    margin-bottom: 0.5rem;
  }
  
  .login-form-header p {
    color: var(--login-secondary);
  }
  
  .login-form {
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .input-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-icon {
    position: absolute;
    left: 1rem;
    color: var(--login-secondary);
    font-size: 1.1rem;
  }
  
  .form-input-login {
    width: 100%;
    padding: 1rem 1rem 1rem 2.75rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--login-border-radius);
    font-size: 1rem;
    transition: var(--login-transition);
  }
  
  .form-input-login:focus {
    outline: none;
    border-color: var(--login-primary);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .form-input-login::placeholder {
    color: #adb5bd;
  }
  
  .input-error {
    border-color: var(--login-danger);
  }
  
  .error-message {
    color: var(--login-danger);
    font-size: 0.85rem;
    margin-top: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .submit-button {
    width: 100%;
    padding: 1rem;
    background: var(--login-gradient);
    color: var(--login-white);
    border: none;
    border-radius: var(--login-border-radius);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--login-transition);
  }
  
  .submit-button:hover {
    box-shadow: 0 4px 15px rgba(74, 108, 247, 0.2);
  }
  
  .submit-button:disabled {
    background: var(--login-secondary);
    cursor: not-allowed;
  }
  
  .login-form-footer {
    text-align: center;
  }
  
  .forgot-password-link,
  .back-to-login-link {
    background: none;
    border: none;
    color: var(--login-primary);
    font-size: 0.9rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: var(--login-transition);
  }
  
  .forgot-password-link:hover,
  .back-to-login-link:hover {
    text-decoration: underline;
  }
  
  .link-icon {
    margin-right: 0.5rem;
  }
  