/* WIPQueue.css */

.wip-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Loading State */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  color: var(--secondary-color, #6c757d);
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color, #4a6cf7);
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Main WIP Card */
.wip-card {
  background-color: var(--card-bg, #ffffff);
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Header */
.wip-header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.client-title-section {
  flex: 1;
}

.client-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.client-icon {
  font-size: 2rem;
  color: var(--primary-color, #4a6cf7);
}

.client-title h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--dark-color, #343a40);
  margin: 0;
}

.client-subtitle {
  color: var(--secondary-color, #6c757d);
  font-size: 1rem;
  margin: 0;
}

/* Summary Cards */
.wip-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.summary-card {
  background-color: var(--light-color, #f8f9fa);
  border-radius: var(--border-radius, 10px);
  padding: 1.5rem;
  transition: var(--transition, all 0.3s ease);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.summary-card-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--secondary-color, #6c757d);
  margin-bottom: 1rem;
  font-weight: 600;
}

.summary-icon {
  font-size: 1.25rem;
}

.summary-value {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.wip-value {
  color: var(--primary-color, #4a6cf7);
}

.invoice-value {
  color: var(--warning-color, #ffc107);
}

.payment-value {
  color: var(--success-color, #28a745);
}

.hours-value {
  color: var(--info-color, #17a2b8);
}

.summary-footer {
  font-size: 0.85rem;
  color: var(--secondary-color, #6c757d);
}

/* Client Details */
.client-details {
  background-color: var(--light-color, #f8f9fa);
  border-radius: var(--border-radius, 10px);
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.details-row {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.detail-item {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--secondary-color, #6c757d);
}

.detail-value {
  font-size: 1rem;
  color: var(--dark-color, #343a40);
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: rgba(40, 167, 69, 0.1);
  color: var(--success-color, #28a745);
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.9rem;
}

/* WIP Actions */
.wip-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.wip-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: var(--border-radius, 10px);
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
  flex: 1;
  justify-content: center;
}

.wip-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.progress-button {
  background-color: var(--primary-color, #4a6cf7);
  color: white;
}

.progress-button:hover:not(:disabled) {
  background-color: #3a5bd8;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(74, 108, 247, 0.2);
}

.closeout-button {
  background-color: var(--danger-color, #dc3545);
  color: white;
}

.closeout-button:hover:not(:disabled) {
  background-color: #c82333;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(220, 53, 69, 0.2);
}

.button-icon {
  font-size: 1.25rem;
}

.no-wip-message {
  background-color: var(--light-color, #f8f9fa);
  border-radius: var(--border-radius, 10px);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.alert-icon {
  font-size: 1.5rem;
  color: var(--warning-color, #ffc107);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-container {
  background-color: var(--card-bg, #ffffff);
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  width: 100%;
  max-width: 550px;
  max-height: 90vh;
  overflow-y: auto;
  animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--dark-color, #343a40);
  margin: 0;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--secondary-color, #6c757d);
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.modal-close:hover {
  color: var(--danger-color, #dc3545);
}

.modal-body {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  color: var(--dark-color, #343a40);
  margin-bottom: 0.75rem;
}

.required-indicator {
  color: var(--danger-color, #dc3545);
  margin-left: 0.25rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius, 10px);
  font-size: 0.95rem;
  transition: var(--transition, all 0.3s ease);
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color, #4a6cf7);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
}

.form-note {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.85rem;
  color: var(--warning-color, #ffc107);
  margin-top: 0.5rem;
}

.note-icon {
  font-size: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.modal-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: var(--border-radius, 10px);
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: var(--transition, all 0.3s ease);
}

.modal-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.cancel-button {
  background-color: var(--secondary-color, #6c757d);
  color: white;
}

.cancel-button:hover:not(:disabled) {
  background-color: #5a6268;
}

.submit-button {
  background-color: var(--primary-color, #4a6cf7);
  color: white;
}

.submit-button:hover:not(:disabled) {
  background-color: #3a5bd8;
}

/* WIP Modal Styles - Add to your WIPQueue.css file */

/* Modal Overlay */
.wip-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  padding: 1rem;
}

/* Modal Container */
.wip-modal {
  background-color: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 550px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
  animation: wip-modal-fadeIn 0.3s ease;
  max-height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@keyframes wip-modal-fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modal Header */
.wip-modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wip-modal-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  display: flex;
  align-items: center;
}

.wip-modal-title-icon {
  margin-right: 10px;
  color: var(--primary-color, #4a6cf7);
  font-size: 1.2rem;
}

.wip-modal-close {
  background: none;
  border: none;
  color: #718096;
  font-size: 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
  width: 36px;
  height: 36px;
}

.wip-modal-close:hover {
  background-color: #f7fafc;
  color: #e53e3e;
}

.wip-modal-close:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Modal Body */
.wip-modal-body {
  padding: 1.5rem;
  overflow-y: auto;
}

/* Form Elements */
.wip-form-group {
  margin-bottom: 1.5rem;
}

.wip-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.wip-form-label {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #4a5568;
}

.wip-form-icon {
  margin-right: 0.5rem;
  color: var(--primary-color, #4a6cf7);
  font-size: 1rem;
}

.wip-required-indicator {
  color: #e53e3e;
  margin-left: 0.25rem;
  font-weight: bold;
}

.wip-form-control {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.wip-form-control:focus {
  outline: none;
  border-color: var(--primary-color, #4a6cf7);
  box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.15);
}

.wip-form-control:disabled {
  background-color: #f7fafc;
  cursor: not-allowed;
}

.wip-form-select {
  width: 100%;
}

.wip-form-helper {
  font-size: 0.875rem;
  color: #718096;
  margin-top: 0.5rem;
}

/* Alert Box */
.wip-form-alert {
  display: flex;
  align-items: flex-start;
  background-color: #fffaf0;
  border: 1px solid #fbd38d;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.wip-alert-icon {
  color: #d69e2e;
  margin-right: 0.75rem;
  margin-top: 0.25rem;
  font-size: 1.1rem;
  flex-shrink: 0;
}

.wip-alert-content {
  flex: 1;
}

.wip-alert-title {
  display: block;
  font-weight: 600;
  color: #d69e2e;
  margin-bottom: 0.25rem;
}

.wip-alert-message {
  margin: 0;
  font-size: 0.875rem;
  color: #744210;
}

/* Client Summary */
.wip-client-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ebf4ff;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.wip-summary-label {
  font-weight: 500;
  color: #4a5568;
}

.wip-summary-value {
  font-weight: 600;
  color: var(--primary-color, #4a6cf7);
}

/* Modal Footer */
.wip-modal-footer {
  padding: 1.25rem 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background-color: #f7fafc;
}

.wip-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  font-size: 0.95rem;
  min-width: 120px;
}

.wip-button-icon {
  margin-right: 0.5rem;
}

.wip-cancel-button {
  background-color: white;
  color: #718096;
  border: 1px solid #e2e8f0;
}

.wip-cancel-button:hover:not(:disabled) {
  background-color: #edf2f7;
}

.wip-submit-button {
  color: white;
}

.wip-invoice-button {
  background-color: var(--primary-color, #4a6cf7);
}

.wip-invoice-button:hover:not(:disabled) {
  background-color: #3b5bdb;
  box-shadow: 0 4px 10px rgba(74, 108, 247, 0.2);
}

.wip-closeout-button {
  background-color: #dd6b20;
}

.wip-closeout-button:hover:not(:disabled) {
  background-color: #c05621;
  box-shadow: 0 4px 10px rgba(221, 107, 32, 0.2);
}

.wip-modal-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}