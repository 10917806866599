/* Activity Types Styles */
:root {
    --primary-color: #4a6cf7;
    --primary-light: #e8effd;
    --secondary-color: #6c757d;
    --background-color: #f5f7fb;
    --card-bg: #ffffff;
    --border-radius: 10px;
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
  }
  
  .activity-types-container {
    max-width: 800px;
    margin: 2rem auto;
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
  }
  
  .activity-types-header {
    background-color: var(--primary-light);
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .activity-types-header h2 {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 700;
    margin: 0;
  }
  
  .activity-types-add-section {
    padding: 1.5rem;
    background-color: var(--background-color);
  }
  
  .add-type-input-group {
    display: flex;
    gap: 1rem;
  }
  
  .add-type-input-group input,
  .add-type-input-group select {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transition: var(--transition);
  }
  
  .add-type-input-group input:focus,
  .add-type-input-group select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px var(--primary-light);
  }
  
  .add-type-input-group button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .add-type-input-group button:hover {
    background-color: #3a5aeb;
  }
  
  .activity-types-empty-state {
    padding: 2rem;
    text-align: center;
    color: var(--secondary-color);
  }
  
  .activity-types-list {
    padding: 1rem;
  }
  
  .activity-type-item {
    background-color: var(--background-color);
    border-radius: 6px;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  
  .activity-type-view-mode,
  .activity-type-edit-mode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    transition: var(--transition);
  }
  
  .activity-type-details {
    display: flex;
    flex-direction: column;
  }
  
  .activity-name {
    font-weight: 600;
    color: var(--secondary-color);
  }
  
  .activity-charge-type {
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
  
  .activity-charge-type.chargeable {
    color: var(--success-color);
  }
  
  .activity-charge-type.non-chargeable {
    color: var(--danger-color);
  }
  
  .activity-type-actions,
  .edit-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  
  .save-btn {
    background-color: var(--success-color);
    color: white;
  }
  
  .cancel-btn {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .activity-type-edit-mode input[type="text"] {
    flex: 1;
    padding: 0.5rem;
    margin-right: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .charge-type-toggle {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  
  .charge-type-toggle input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .activity-types-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: var(--primary-color);
  }
  
  .spinner {
    border: 4px solid var(--primary-light);
    border-top: 4px solid var(--primary-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
