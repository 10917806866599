/* AdminPage.css */

.admin-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .admin-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .admin-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .admin-subtitle {
    color: var(--secondary-color, #6c757d);
    font-size: 1rem;
  }
  
  .admin-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .admin-card {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    overflow: hidden;
    transition: var(--transition, all 0.3s ease);
    border: 1px solid rgba(0, 0, 0, 0.05);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .admin-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .admin-card-header {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    font-size: 1.5rem;
  }
  
  .card-blue .card-icon {
    background-color: rgba(74, 108, 247, 0.1);
    color: #4a6cf7;
  }
  
  .card-green .card-icon {
    background-color: rgba(40, 167, 69, 0.1);
    color: #28a745;
  }
  
  .card-purple .card-icon {
    background-color: rgba(111, 66, 193, 0.1);
    color: #6f42c1;
  }
  
  .card-orange .card-icon {
    background-color: rgba(255, 153, 0, 0.1);
    color: #ff9900;
  }
  
  .card-red .card-icon {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .admin-card-content {
    padding: 0 1.5rem 1.5rem;
    flex: 1;
  }
  
  .admin-link-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .admin-link-list li {
    margin-bottom: 0.75rem;
  }
  
  .admin-link-list li:last-child {
    margin-bottom: 0;
  }
  
  .admin-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: var(--border-radius, 10px);
    color: var(--text-color, #495057);
    text-decoration: none;
    transition: var(--transition, all 0.3s ease);
    background-color: var(--light-color, #f8f9fa);
    font-weight: 500;
  }
  
  .link-icon {
    margin-right: 12px;
    font-size: 1.1rem;
    opacity: 0.8;
  }
  
  .admin-link:hover {
    background-color: var(--primary-light, #e8effd);
    color: var(--primary-color, #4a6cf7);
    transform: translateX(5px);
  }
  
  .card-blue .admin-link:hover {
    color: #4a6cf7;
  }
  
  .card-green .admin-link:hover {
    color: #28a745;
  }
  
  .card-purple .admin-link:hover {
    color: #6f42c1;
  }
  
  .card-orange .admin-link:hover {
    color: #ff9900;
  }
  
  .card-red .admin-link:hover {
    color: #dc3545;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .admin-container {
      padding: 1rem;
    }
    
    .admin-card-grid {
      grid-template-columns: 1fr;
    }
    
    .admin-card-header {
      padding: 1.25rem;
    }
    
    .admin-card-content {
      padding: 0 1.25rem 1.25rem;
    }
  }