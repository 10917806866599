/* UserManagement.css */
:root {
    --users-primary: #4a6cf7;
    --users-primary-light: #e8effd;
    --users-secondary: #6c757d;
    --users-danger: #dc3545;
    --users-success: #28a745;
    --users-warning: #ffc107;
    --users-info: #17a2b8;
    --users-dark: #343a40;
    --users-light: #f8f9fa;
    --users-white: #ffffff;
    --users-text: #495057;
    --users-border: rgba(0, 0, 0, 0.1);
    --users-radius: 10px;
    --users-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    --users-transition: all 0.2s ease-in-out;
  }
  
  /* Container */
  .user-management-container {
    padding: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header section */
  .user-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .header-title {
    display: flex;
    align-items: center;
  }
  
  .header-icon {
    font-size: 1.5rem;
    color: var(--users-primary);
    margin-right: 0.75rem;
  }
  
  .header-title h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--users-dark);
    margin: 0;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .refresh-button,
  .view-toggle-button {
    background-color: var(--users-white);
    border: 1px solid var(--users-border);
    color: var(--users-secondary);
    border-radius: var(--users-radius);
    padding: 0.65rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--users-transition);
    font-size: 1.1rem;
  }
  
  .refresh-button:hover,
  .view-toggle-button:hover {
    background-color: var(--users-primary-light);
    color: var(--users-primary);
  }
  
  .refresh-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .create-user-button {
    background-color: var(--users-primary);
    color: var(--users-white);
    border: none;
    border-radius: var(--users-radius);
    padding: 0.65rem 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: var(--users-transition);
  }
  
  .create-user-button:hover {
    background-color: #3a5ad9;
    box-shadow: 0 2px 8px rgba(74, 108, 247, 0.25);
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  
  /* Search section */
  .search-container {
    display: flex;
    margin-bottom: 1.5rem;
    gap: 0.75rem;
  }
  
  .search-input-group {
    flex: 1;
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--users-secondary);
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem 2.5rem;
    border-radius: var(--users-radius);
    border: 1px solid var(--users-border);
    font-size: 0.95rem;
    transition: var(--users-transition);
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--users-primary);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .clear-search-button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.25rem;
    color: var(--users-secondary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    line-height: 1;
  }
  
  .clear-search-button:hover {
    color: var(--users-danger);
  }
  
  .clear-search-button.secondary {
    position: static;
    transform: none;
    padding: 0.5rem 1rem;
    background-color: var(--users-light);
    border-radius: var(--users-radius);
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  
  .search-button {
    background-color: var(--users-primary);
    color: var(--users-white);
    border: none;
    border-radius: var(--users-radius);
    padding: 0 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: var(--users-transition);
    white-space: nowrap;
  }
  
  .search-button:hover {
    background-color: #3a5ad9;
  }
  
  /* No results */
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    text-align: center;
    background-color: var(--users-light);
    border-radius: var(--users-radius);
    color: var(--users-secondary);
  }
  
  .no-results-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    opacity: 0.6;
  }
  
  /* Table view */
  .table-container {
    overflow-x: auto;
    border-radius: var(--users-radius);
    box-shadow: var(--users-shadow);
    margin-bottom: 2rem;
  }
  
  .users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.95rem;
  }
  
  .users-table th {
    background-color: var(--users-light);
    color: var(--users-dark);
    font-weight: 600;
    text-align: left;
    padding: 1rem;
    cursor: pointer;
    user-select: none;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: var(--users-transition);
  }
  
  .users-table th.sorted {
    background-color: var(--users-primary-light);
    color: var(--users-primary);
  }
  
  .users-table th:hover {
    background-color: var(--users-primary-light);
  }
  
  .th-content-u {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .th-icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
  
  .sort-icon {
    margin-left: 0.5rem;
    font-size: 0.9rem;
  }
  
  .users-table td {
    padding: 1rem;
    border-top: 1px solid var(--users-border);
    background-color: var(--users-white);
    transition: var(--users-transition);
  }
  
  .users-table tr:hover td {
    background-color: var(--users-primary-light);
  }
  
  .username-cell {
    font-weight: 500;
  }
  
  .role-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .role-partner {
    background-color: #f0f4ff;
    color: #4a6cf7;
  }
  
  .role-manager {
    background-color: #fff4e6;
    color: #fd7e14;
  }
  
  .role-staff {
    background-color: #e6f9ff;
    color: #17a2b8;
  }
  
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .status-active {
    background-color: #e6f9f0;
    color: #28a745;
  }
  
  .status-inactive {
    background-color: #f8f9fa;
    color: #6c757d;
  }
  
  .actions-cell {
    display: flex;
    gap: 0.5rem;
  }
  
  .action-button-um {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    /* width: 32px; */
    height: 32px;
    border-radius: var(--users-radius);
    cursor: pointer;
    transition: var(--users-transition);
    font-size: 1rem;
  }
  
  .action-button-um.edit {
    color: var(--users-primary);
  }
  
  .action-button-um.edit:hover {
    background-color: var(--users-primary-light);
  }
  
  .action-button-um.delete {
    color: var(--users-danger);
  }
  
  .action-button-um.delete:hover {
    background-color: #ffe8e8;
  }
  
  /* Card view */
  .user-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .user-card {
    border-radius: var(--users-radius);
    overflow: hidden;
    box-shadow: var(--users-shadow);
    border: 1px solid var(--users-border);
    background-color: var(--users-white);
    transition: var(--users-transition);
  }
  
  .user-card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
  
  .user-card-header {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    background-color: var(--users-primary-light);
    border-bottom: 1px solid var(--users-border);
  }
  
  .user-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--users-primary);
    color: var(--users-white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 600;
    margin-right: 1rem;
  }
  
  .user-info {
    flex: 1;
  }
  
  .user-name {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: var(--users-dark);
  }
  
  .user-card-content {
    padding: 1.25rem;
  }
  
  .user-detail {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  
  .detail-icon {
    color: var(--users-primary);
    margin-right: 0.5rem;
    font-size: 1rem;
  }
  
  .detail-label {
    font-weight: 500;
    margin-right: 0.5rem;
    color: var(--users-dark);
  }
  
  .detail-value {
    color: var(--users-text);
    word-break: break-word;
  }
  
  .user-rates {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--users-border);
  }
  
  .rates-header {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 0.75rem;
    color: var(--users-dark);
  }
  
  .rates-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .rate-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px dashed var(--users-border);
  }
  
  .rate-item:last-child {
    border-bottom: none;
  }
  
  .rate-name {
    font-weight: 500;
  }
  
  .rate-value {
    color: var(--users-success);
    font-weight: 600;
  }
  
  .no-rates {
    color: var(--users-secondary);
    font-style: italic;
    font-size: 0.9rem;
  }
  
  .user-card-footer {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--users-border);
    background-color: var(--users-light);
  }
  
  .card-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem 1.25rem;
    border-radius: var(--users-radius);
    cursor: pointer;
    transition: var(--users-transition);
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  .card-action-button.edit {
    background-color: var(--users-white);
    color: var(--users-primary);
    border: 1px solid var(--users-primary);
  }
  
  .card-action-button.edit:hover {
    background-color: var(--users-primary-light);
  }
  