.dashboard {
    display: flex;

}



.sidebar {
    background-color: #2c3e50;
    color: #ecf0f1;
    width: 250px;
    height: auto;
    transition: width 0.3s;
    overflow: hidden;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.no-partners, .no-invoices, .no-activities {
    background-color: #ffe8e8!important;
    color: #d9534f!important;
    padding: 1rem!important;
    border: 1px solid #d9534f!important;
    border-radius: 0.25rem!important;
    margin-top: 1rem!important;
}

.dashboard-sidebar.collapsed {
    width: 100px;
}

.fart{
    width: 400px;
    height: 495px;
}



input#username1,input#password1, .fart2{
    max-width: 205px;
}


.collapse-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin: 10px;
}

.sidebar h2 {
    margin-bottom: 20px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin: 15px 0;
}

.sidebar ul li a {
    color: #ecf0f1;
    text-decoration: none;
    transition: color 0.3s;
}

.sidebar ul li a:hover {
    color: #3498db;
}

.main-content {
    flex: 1;
    /* padding: 20px; */
    padding-left: var(--sidebar-width);
    transition: var(--transition);
    background-color: #ecf0f1;
}

ol, ul {
    padding-left: 0px!important;
}


.dashboard-header {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.dashboard-content {
    display: flex;
    flex-direction: column;
}

.dashboard-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.dashboard-card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.dashboard-card-header {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.dashboard-card-body {
    text-align: center;
}

.dashboard-button {
    display: inline-block;
    padding: 10px 15px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.dashboard-button:hover {
    background-color: #2980b9;
}

.dashboard-list-group {
    list-style: none;
    padding: 0;
}

.dashboard-list-group-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.invoices-table {
    width: 100%;
    border-collapse: collapse;
}

.invoices-table th, .invoices-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        position: relative;
    }

    .dashboard {
        flex-direction: column;
    }
    
    .main-content {
        padding: 10px;
    }
}

/* Dashboard.css */
:root {
    --primary-color: #4a6cf7;
    --primary-light: #e8effd;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --dark-color: #343a40;
    --light-color: #f8f9fa;
    --text-color: #495057;
    --body-bg: #f5f7fb;
    --sidebar-width: 250px;
    --sidebar-bg: #ffffff;
    --card-bg: #ffffff;
    --border-radius: 10px;
    --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    --transition: all 0.3s ease;
  }
  
  /* Base styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    background-color: var(--body-bg);
    color: var(--text-color);
    line-height: 1.6;
  }
  
  a {
    text-decoration: none!important;
    color: var(--primary-color);
  }
  
  ul {
    list-style-type: none;
  }

  
  /* Dashboard Container */
  .dashboard-container {
    display: flex;
    min-height: 100vh;
  }
  
  /* Sidebar */
  .dashboard-sidebar {
    /* width: var(--sidebar-width); */
    background-color: var(--sidebar-bg);
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
    /* position: fixed; */
    height: 100vh;
    overflow-y: auto;
    z-index: 1000;
    transition: var(--transition);
  }
  
  .sidebar-header {
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .app-logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
  }
  
  /* .sidebar-nav {
    padding: 1rem 0;
  } */
  
  .nav-item {
    margin: 0.25rem 0;
  }
  
  .nav-item a {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    color: var(--secondary-color);
    transition: var(--transition);
    border-left: 3px solid transparent;
  }
  
  .nav-item a svg {
    margin-right: 10px;
    font-size: 1.2rem;
  }
  
  .nav-item a:hover {
    background-color: var(--primary-light);
    color: var(--primary-color);
    border-left-color: var(--primary-color);
  }
  
  .nav-item.active a {
    background-color: var(--primary-light);
    color: var(--primary-color);
    border-left-color: var(--primary-color);
    font-weight: 600;
  }
  
  /* Main Content */
  .dashboard-main {
    flex: 1;
    /* margin-left: var(--sidebar-width); */
    padding: 1.5rem;
    transition: var(--transition);
  }
  
  /* Top Bar */
  .dashboard-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .topbar-date {
    display: flex;
    flex-direction: column;
  }
  
  .topbar-date .date {
    font-weight: 600;
    color: var(--text-color);
  }
  
  .topbar-date .time {
    color: var(--secondary-color);
    font-size: 0.875rem;
  }
  
  .topbar-user {
    display: flex;
    align-items: center;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 10px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
  }
  
  .user-name {
    font-weight: 600;
    color: var(--text-color);
  }
  
  .user-role {
    color: var(--secondary-color);
    font-size: 0.875rem;
  }
  
  /* Welcome Section */
  .dashboard-welcome {
    margin-bottom: 2rem;
  }
  
  .dashboard-welcome h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color);
    margin-bottom: 0.5rem;
  }
  
  .dashboard-welcome p {
    color: var(--secondary-color);
  }
  
  /* Quick Links Section */
  .dashboard-quicklinks {
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--dark-color);
  }
  
  .quicklinks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .quicklink-card {
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    transition: var(--transition);
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .quicklink-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-color: var(--primary-color);
  }
  
  .quicklink-icon {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: var(--primary-light);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
  .quicklink-info h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: var(--dark-color);
  }
  
  .quicklink-info p {
    color: var(--secondary-color);
    font-size: 0.875rem;
  }
  
  /* Dashboard Stats Container */
  .dashboard-stats-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .dashboard-partners,
  .dashboard-invoices,
  .dashboard-activities {
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  /* Partners List */
  .partners-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .partner-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--light-color);
    transition: var(--transition);
  }
  
  .partner-card:hover {
    background-color: var(--primary-light);
  }
  
  .partner-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 10px;
  }
  
  .partner-details h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: var(--dark-color);
  }
  
  .partner-details p {
    color: var(--secondary-color);
    font-size: 0.75rem;
  }
  
  /* Invoices List */
  .invoice-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .invoice-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--light-color);
    transition: var(--transition);
  }
  
  .invoice-card:hover {
    background-color: var(--primary-light);
  }
  
  .invoice-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(74, 108, 247, 0.1);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    margin-right: 10px;
  }
  
  .invoice-details h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: var(--dark-color);
  }
  
  .invoice-details p {
    color: var(--secondary-color);
    font-size: 0.75rem;
  }
  
  /* Activities Timeline */
  .activity-timeline {
    position: relative;
    padding-left: 20px!important;
  }
  
  .activity-timeline::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: var(--primary-light);
  }
  
  .activity-item {
    position: relative;
    padding-bottom: 1.25rem;
  }
  
  .activity-dot {
    position: absolute;
    left: -20px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary-color);
  }
  
  .activity-content {
    padding: 0.5rem 0;
  }
  
  .activity-content p {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
  }
  
  .activity-time {
    color: var(--secondary-color);
    font-size: 0.75rem;
  }
  
  /* Empty States */
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    color: var(--secondary-color);
  }
  
  .empty-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    opacity: 0.3;
  }
  
  /* View All Links */
  .view-all-link {
    display: block;
    text-align: center;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: var(--border-radius);
    background-color: var(--light-color);
    transition: var(--transition);
  }
  
  .view-all-link:hover {
    background-color: var(--primary-light);
    color: var(--primary-color);
  }
  
  /* Responsive Adjustments */


 /* Add these styles to your Dashboard.css */

/* Sidebar Specific Styles */
.dashboard-sidebar {
    width: var(--sidebar-width);
    background-color: var(--sidebar-bg);
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    z-index: 1000;
    transition: var(--transition);
  }
  
  .dashboard-sidebar.collapsed {
    width: 70px;
  }
  
  .sidebar-header {
    padding: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dashboard-sidebar.collapsed .sidebar-header {
    justify-content: center;
    padding: 1.5rem 0.5rem;
  }
  
  .app-logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    margin: 0;
  }
  
  .collapse-btn {
    background: none;
    border: none;
    color: var(--secondary-color);
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
  }
  
  .collapse-btn:hover {
    color: var(--primary-color);
  }
  
  .sidebar-nav {
    padding: 1rem 0;
  }
  
  .nav-item {
    margin: 0.25rem 0;
  }
  
  .nav-item a {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    color: var(--secondary-color);
    transition: var(--transition);
    border-left: 3px solid transparent;
    text-decoration: none;
  }
  
  .nav-item a svg {
    margin-right: 10px;
    font-size: 1.2rem;
    min-width: 1.2rem; /* Ensures the icon has a fixed width */
  }
  
  .dashboard-sidebar.collapsed .nav-item a {
    padding: 0.75rem;
    justify-content: center;
  }
  
  .dashboard-sidebar.collapsed .nav-item a svg {
    margin-right: 0;
    font-size: 1.5rem;
  }
  
  .dashboard-sidebar.collapsed .nav-text {
    display: none;
  }
  
  .nav-item a:hover, 
  .nav-item a.active {
    background-color: var(--primary-light);
    color: var(--primary-color);
    border-left-color: var(--primary-color);
  }
  
  .nav-item a.active {
    font-weight: 600;
  }
  
  .logout-item {
    margin-top: 2rem;
  }
  
  .logout-item a {
    color: var(--danger-color);
  }
  
  .logout-item a:hover {
    background-color: rgba(220, 53, 69, 0.1);
    color: var(--danger-color);
    border-left-color: var(--danger-color);
  }
  
  /* Main Content Adjustments */
  .dashboard-main {
    flex: 1;
    /* margin-left: var(--sidebar-width); */
    padding: 1.5rem;
    transition: var(--transition);
    width: calc(100% - var(--sidebar-width));
  }
  
  .dashboard-main.expanded {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
  
  /* Responsive Adjustments */
  