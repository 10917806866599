.toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0 10px;
  }
  
  .toolbar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s ease;
    min-width: 60px;
  }
  
  .toolbar-item:hover {
    background-color: #f5f5f5;
  }
  
  .toolbar-icon {
    font-size: 20px;
    margin-bottom: 4px;
    color: #4a6cf7;
  }
  
  .toolbar-label {
    font-size: 12px;
    color: #555;
    font-weight: 500;
  }
  
  .toolbar-item.highlight {
    background-color: #4a6cf7;
  }
  
  .toolbar-item.highlight .toolbar-icon,
  .toolbar-item.highlight .toolbar-label {
    color: white;
  }
  
  .toolbar-item.highlight:hover {
    background-color: #3a5ce5;
  }
  
  /* Make sure content doesn't get hidden behind the toolbar */
  .app-content {
    padding-bottom: 70px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .toolbar {
      overflow-x: auto;
      justify-content: flex-start;
    }
    
    .toolbar-item {
      min-width: 50px;
      padding: 8px 6px;
    }
    
    .toolbar-label {
      font-size: 10px;
    }
  }
  
  /* Hide scrollbar but allow scrolling on mobile */
  .toolbar::-webkit-scrollbar {
    display: none;
  }
  
  .toolbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }