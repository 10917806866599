/* Main layout */
.user-guide-container {
    display: flex;
    min-height: 100vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
  }
  
  /* Sidebar */
  .guide-sidebar {
    width: 280px;
    background-color: #f8f9fa;
    border-right: 1px solid #e9ecef;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    padding: 20px 0;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
    z-index: 10;
  }
  
  .guide-logo {
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .guide-logo-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #4a6bff;
  }
  
  .guide-logo h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  
  .guide-search {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  
  .search-input-container {
    position: relative;
    width: 100%;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
  }
  
  .search-input {
    width: 100%;
    padding: 10px 30px 10px 35px;
    border-radius: 20px;
    border: 1px solid #ced4da;
    font-size: 14px;
    transition: all 0.2s;
  }
  
  .search-input:focus {
    outline: none;
    border-color: #4a6bff;
    box-shadow: 0 0 0 3px rgba(74, 107, 255, 0.25);
  }
  
  .clear-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #6c757d;
    padding: 0;
    width: 20px;
    height: 20px;
    line-height: 1;
  }
  
  .clear-search:hover {
    color: #dc3545;
  }
  
  /* Navigation */
  .guide-nav {
    padding: 0 10px;
  }
  
  .nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    margin-bottom: 5px;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    color: #495057;
    text-decoration: none;
    transition: all 0.2s;
  }
  
  .nav-link:hover {
    background-color: #e9ecef;
    color: #4a6bff;
  }
  
  .nav-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .subnav-list {
    list-style: none;
    margin: 5px 0 10px 30px;
    padding: 0;
  }
  
  .subnav-list li {
    margin-bottom: 5px;
  }
  
  .subnav-list a {
    display: block;
    padding: 6px 10px;
    border-radius: 4px;
    color: #6c757d;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s;
  }
  
  .subnav-list a:hover {
    background-color: #e9ecef;
    color: #4a6bff;
  }
  
  /* Main content */
  .guide-content {
    flex: 1;
    padding: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .guide-header {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .guide-header h1 {
    margin-bottom: 15px;
    color: #212529;
    font-size: 32px;
    font-weight: 700;
  }
  
  .guide-intro {
    font-size: 18px;
    color: #6c757d;
    max-width: 700px;
    margin: 0 auto;
  }
  
  /* Sections */
  .guide-section {
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .guide-section h2 {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 24px;
    color: #343a40;
    padding-bottom: 15px;
    border-bottom: 2px solid #f1f3f5;
  }
  
  .section-icon {
    font-size: 22px;
    margin-right: 10px;
    color: #4a6bff;
  }
  
  .guide-subsection {
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  
  .guide-subsection h3 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #343a40;
    font-weight: 600;
  }
  
  /* Step-by-step guides */
  .step-by-step {
    margin-bottom: 20px;
  }
  
  .step {
    display: flex;
    margin-bottom: 15px;
  }
  
  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #4a6bff;
    color: white;
    border-radius: 50%;
    font-weight: 600;
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .step-content {
    flex: 1;
    padding-top: 3px;
  }
  
  .substep-list {
    margin: 10px 0 0 0;
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .substep-list li {
    margin-bottom: 5px;
  }
  
  /* Notes */
  .info-note, .tip-note, .warning-note {
    padding: 15px;
    margin: 20px 0;
    border-radius: 8px;
    position: relative;
  }
  
  .info-note {
    background-color: #e7f5ff;
    border-left: 4px solid #4dabf7;
  }
  
  .tip-note {
    background-color: #ebfbee;
    border-left: 4px solid #40c057;
  }
  
  .warning-note {
    background-color: #fff9db;
    border-left: 4px solid #fcc419;
  }
  
  /* Feature grid */
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    margin: 20px 0;
  }
  
  .feature-card {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
  }
  
  .feature-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon {
    font-size: 24px;
    margin-bottom: 15px;
    color: #4a6bff;
  }
  
  .feature-title {
    font-weight: 600;
    margin-bottom: 10px;
    color: #343a40;
  }
  
  .feature-desc {
    color: #6c757d;
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* Code formatting */
  code {
    background-color: #f1f3f5;
    padding: 2px 5px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: #e83e8c;
  }
  
  /* Footer */
  .guide-footer {
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-content {
    color: #6c757d;
  }
  
  .copyright {
    font-size: 14px;
    color: #adb5bd;
    margin-top: 10px;
  }
  
  .back-to-top {
    display: flex;
    align-items: center;
    background-color: #4a6bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
  }
  
  .back-to-top svg {
    margin-right: 5px;
  }
  
  .back-to-top:hover {
    background-color: #3559ff;
    transform: translateY(-2px);
  }
  
  /* Search highlighting */
  .search-highlight {
    background-color: #fff3bf;
    padding: 0 2px;
    border-radius: 2px;
  }
  
