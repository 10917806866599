/* TimeEntryForm.css */

.time-entry-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Header Styles */
  .time-entry-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .header-title-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .header-icon {
    font-size: 1.75rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .header-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .header-subtitle {
    color: var(--secondary-color, #6c757d);
    font-size: 1rem;
    margin: 0;
  }
  
  /* Form Styles */
  .time-entry-form {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: var(--dark-color, #343a40);
  }
  
  .field-icon {
    color: var(--primary-color, #4a6cf7);
    font-size: 1rem;
  }
  
  .form-input {
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius, 10px);
    font-size: 0.9rem;
    transition: var(--transition, all 0.3s ease);
  }
  
  .form-input:focus {
    outline: none;
    border-color: var(--primary-color, #4a6cf7);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .form-input.is-invalid,
  .form-select.is-invalid {
    border-color: var(--danger-color, #dc3545);
  }
  
  .form-input:disabled {
    background-color: var(--light-color, #f8f9fa);
    cursor: not-allowed;
  }
  
  .total-input {
    font-weight: 700;
    color: var(--primary-color, #4a6cf7);
    background-color: var(--light-color, #f8f9fa);
  }
  
  .error-message {
    color: var(--danger-color, #dc3545);
    font-size: 0.8rem;
    margin-top: 0.25rem;
  }
  
  /* Action Buttons */
  .form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    flex-wrap: wrap;
  }
  
  .submit-button{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .submit-button {
    background-color: var(--primary-color, #4a6cf7);
    color: white;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #3a5bd8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(74, 108, 247, 0.2);
  }
  
  .delete-button {
    background-color: var(--danger-color, #dc3545);
    color: white;
  }
  
  .delete-button:hover:not(:disabled) {
    background-color: #c82333;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(220, 53, 69, 0.2);
  }
  
  .submit-button:disabled,
  .delete-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* Spinner animation for loading states */
  .spinner {
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  