/* CustomNotifications.css */

/* Container for notifications */
.custom-notifications-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    padding-right: 10px;
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
  }
  
  .custom-notifications-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
  }
  
  /* Individual notification */
  .custom-notification {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: white;
    animation: slideIn 0.3s ease-out forwards;
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  /* Important notifications appear at the top */
  .custom-notification.important {
    order: -1;
    background-color: #d32f2f;
    color: white;
    animation: pulse 2s infinite;
  }
  
  /* Notification types */
  .custom-notification.success {
    border-left: 4px solid #4caf50;
  }
  
  .custom-notification.error {
    border-left: 4px solid #f44336;
  }
  
  .custom-notification.info {
    border-left: 4px solid #2196f3;
  }
  
  .custom-notification.loading {
    border-left: 4px solid #ff9800;
  }
  
  .custom-notification.choice {
    border-left: 4px solid #9c27b0;
  }
  
  /* Icon section */
  .notification-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-notification.success .notification-icon {
    color: #4caf50;
  }
  
  .custom-notification.error .notification-icon {
    color: #f44336;
  }
  
  .custom-notification.info .notification-icon {
    color: #2196f3;
  }
  
  .custom-notification.loading .notification-icon {
    color: #ff9800;
  }
  
  .custom-notification.choice .notification-icon {
    color: #9c27b0;
  }
  
  /* Important notification icons are white */
  .custom-notification.important .notification-icon {
    color: white;
  }
  
  /* Content section */
  .notification-content {
    flex: 1;
    margin-right: 24px;
  }
  
  .notification-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .notification-message {
    font-size: 14px;
    line-height: 1.4;
    word-break: break-word;
  }
  
  /* Close button */
  .notification-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .notification-close:hover {
    opacity: 1;
  }
  
  .custom-notification.important .notification-close {
    color: white;
  }
  
  /* Action buttons for choice notifications */
  .notification-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    width: 100%;
  }
  
  .action-button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .action-button.confirm {
    background-color: #4a6cf7;
    color: white;
  }
  
  .action-button.confirm:hover {
    background-color: #3a5ad9;
  }
  
  .action-button.cancel {
    background-color: #f1f3f5;
    color: #495057;
  }
  
  .action-button.cancel:hover {
    background-color: #e9ecef;
  }
  
  /* Spinning animation for loading icon */
  .spinning-icon {
    animation: spin 1.5s linear infinite;
  }
  
  /* Animations */
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(211, 47, 47, 0.4);
    }
    70% {
      box-shadow: 0 0 0 8px rgba(211, 47, 47, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(211, 47, 47, 0);
    }
  }
  
  /* Mobile responsiveness */
  /* @media (max-width: 480px) {
    .custom-notifications-container {
      top: 10px;
      right: 10px;
      left: 10px;
      max-width: calc(100% - 20px);
    }
    
    .notification-actions {
      flex-direction: row;
    }
    
    .action-button {
      flex: 1;
    }
  } */