/* InvoiceTextManagement.css */
:root {
    --template-primary: #4a6cf7;
    --template-primary-light: #e8effd;
    --template-secondary: #6c757d;
    --template-danger: #dc3545;
    --template-success: #28a745;
    --template-warning: #ffc107;
    --template-info: #17a2b8;
    --template-dark: #343a40;
    --template-light: #f8f9fa;
    --template-white: #ffffff;
    --template-border: rgba(0, 0, 0, 0.1);
    --template-text: #495057;
    --template-radius: 10px;
    --template-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    --template-transition: all 0.2s ease-in-out;
  }
  
  /* Container */
  .invoice-management-container {
    padding: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header */
  .invoice-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .header-title {
    display: flex;
    align-items: center;
  }
  
  .header-icon {
    font-size: 1.5rem;
    color: var(--template-primary);
    margin-right: 0.75rem;
  }
  
  .header-title h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--template-dark);
    margin: 0;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .refresh-button {
    background-color: var(--template-white)!important;
    border: 1px solid var(--template-border);
    color: var(--template-secondary);
    border-radius: var(--template-radius);
    padding: 0.65rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--template-transition);
    font-size: 1.1rem;
  }
  
  .refresh-button:hover {
    background-color: var(--template-primary-light);
    color: var(--template-primary);
  }
  
  .refresh-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .create-template-button {
    background-color: var(--template-primary);
    color: var(--template-white);
    border: none;
    border-radius: var(--template-radius);
    padding: 0.65rem 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: var(--template-transition);
    text-decoration: none;
  }
  
  .create-template-button:hover {
    background-color: #3a5ad9;
    box-shadow: 0 2px 8px rgba(74, 108, 247, 0.25);
  }
  
  .button-icon {
    margin-right: 0.5rem;
  }
  
  /* Search section */
  .search-container {
    display: flex;
    margin-bottom: 1.5rem;
    gap: 0.75rem;
  }
  
  .search-input-group {
    flex: 1;
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--template-secondary);
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem 2.5rem;
    border-radius: var(--template-radius);
    border: 1px solid var(--template-border);
    font-size: 0.95rem;
    transition: var(--template-transition);
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--template-primary);
    box-shadow: 0 0 0 3px rgba(74, 108, 247, 0.1);
  }
  
  .clear-search-button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.25rem;
    color: var(--template-secondary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    line-height: 1;
  }
  
  .clear-search-button:hover {
    color: var(--template-danger);
  }
  
  .clear-search-button.secondary {
    position: static;
    transform: none;
    padding: 0.5rem 1rem;
    background-color: var(--template-light);
    border-radius: var(--template-radius);
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  
  .search-button {
    background-color: var(--template-primary);
    color: var(--template-white);
    border: none;
    border-radius: var(--template-radius);
    padding: 0 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: var(--template-transition);
    white-space: nowrap;
  }
  
  .search-button:hover {
    background-color: #3a5ad9;
  }
  
  /* Loading and No Results */
  .loading-container,
  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    text-align: center;
    background-color: var(--template-light);
    border-radius: var(--template-radius);
    color: var(--template-secondary);
    margin-bottom: 2rem;
  }
  
  .loading-icon,
  .no-results-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    opacity: 0.6;
  }
  
  /* Table */
  .templates-table-container {
    overflow-x: auto;
    border-radius: var(--template-radius);
    box-shadow: var(--template-shadow);
    margin-bottom: 2rem;
  }
  
  .templates-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.95rem;
  }
  
  .templates-table th {
    background-color: var(--template-light);
    color: var(--template-dark);
    font-weight: 600;
    text-align: left;
    padding: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .templates-table td {
    padding: 1rem;
    border-top: 1px solid var(--template-border);
    background-color: var(--template-white);
  }
  
  .templates-table tr:hover td {
    background-color: var(--template-primary-light);
  }
  
  .template-name-column {
    width: 25%;
  }
  
  .template-content-column {
    width: 55%;
  }
  
  .template-actions-column {
    width: 20%;
  }
  
  .template-name-cell {
    font-weight: 500;
  }
  
  .template-content-cell {
    color: var(--template-secondary);
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .template-actions-cell {
    display: flex;
    gap: 0.5rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 36px; */
    /* height: 36px; */
    border-radius: var(--template-radius);
    cursor: pointer;
    transition: var(--template-transition);
    color: var(--template-white);
    border: none;
    font-size: 1rem;
  }
  
  .action-button.preview {
    background-color: var(--template-info);
  }
  
  .action-button.preview:hover {
    background-color: #138496;
  }
  
  .action-button.edit {
    background-color: var(--template-primary);
    text-decoration: none;
  }
  
  .action-button.edit:hover {
    background-color: #3a5ad9;
  }
  
  .action-button.delete {
    background-color: var(--template-danger);
  }
  
  .action-button.delete:hover {
    background-color: #c82333;
  }
  
  /* Template Preview Modal */
  .template-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
    backdrop-filter: blur(5px);
  }
  
  .template-preview-modal {
    background-color: var(--template-white);
    border-radius: var(--template-radius);
    width: 100%;
    max-width: 800px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    animation: modalFadeIn 0.3s ease;
  }
  
  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .preview-header {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--template-border);
  }
  
  .preview-header h3 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--template-dark);
  }
  
  .close-preview-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--template-secondary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
  
  .close-preview-button:hover {
    color: var(--template-danger);
  }
  
  .preview-content {
    padding: 1.5rem;
    overflow-y: auto;
    flex: 1;
  }
  
  .preview-content pre {
    white-space: pre-wrap;
    font-family: inherit;
    margin: 0;
    line-height: 1.6;
  }
  
  .preview-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--template-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--template-light);
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .preview-note {
    display: flex;
    align-items: center;
    color: var(--template-secondary);
    font-size: 0.9rem;
  }
  
  .info-icon {
    margin-right: 0.5rem;
    color: var(--template-info);
  }
  
  .preview-note code {
    background-color: var(--template-white);
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 0.85rem;
  }
  
  .preview-actions {
    display: flex;
    gap: 0.75rem;
  }
  
  .edit-button {
    background-color: var(--template-primary);
    color: var(--template-white);
    border: none;
    border-radius: var(--template-radius);
    padding: 0.65rem 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: var(--template-transition);
    text-decoration: none;
  }
  
  .edit-button:hover {
    background-color: #3a5ad9;
  }
  
  /* Animation */
  .spin {
    animation: spinning 1.2s linear infinite;
  }
  
  @keyframes spinning {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  