

.settings-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .settings-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .settings-title-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .settings-icon /* Add these styles to your SettingsPage.css file */

  /* Notification Options Section */
  .notification-options {
    margin-top: 1rem;
  }
  
  .notification-option-group {
    background-color: var(--white, #ffffff);
    border-radius: var(--border-radius, 10px);
    padding: 1.5rem;
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
  }
  
  .notification-option-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;
    margin: 1.5rem 0;
  }
  
  .notification-option-button {
    display: flex;
    align-items: flex-start;
    background-color: var(--light, #f8f9fa);
    border: 2px solid #eaeaea;
    border-radius: var(--border-radius, 10px);
    padding: 1.25rem;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    position: relative;
    overflow: hidden;
  }
  
  .notification-option-button.active {
    border-color: var(--primary-color, #4a6cf7);
    background-color: var(--primary-light, #e8effd);
  }
  
  .notification-option-icon-container {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    position: relative;
  }
  
  .notification-option-button.active .notification-option-icon-container {
    color: var(--primary-color, #4a6cf7);
  }
  
  .selected-icon {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--primary-color, #4a6cf7);
    font-size: 1.25rem;
  }
  
  .notification-option-content {
    flex: 1;
  }
  
  .notification-option-content h4 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    font-weight: 600;
    color: var(--dark, #343a40);
  }
  
  .notification-option-content p {
    margin: 0;
    font-size: 0.85rem;
    color: var(--secondary, #6c757d);
    line-height: 1.4;
  }
  
  .notification-option-button:hover {
    background-color: var(--primary-light, #e8effd);
    transform: translateY(-2px);
  }
  
  /* Notification Preview Section */
  .notification-preview {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .preview-title {
    margin: 0 0 1rem 0;
    font-size: 0.95rem;
    font-weight: 600;
    color: var(--dark, #343a40);
  }
  
  .notification-preview-box {
    padding: 1.5rem;
    border-radius: var(--border-radius, 10px);
    background-color: var(--light, #f8f9fa);
    display: flex;
    justify-content: center;
  }
  
  /* Standard Notification Preview */
  .standard-preview .preview-notification {
    background-color: #388e3c;
    color: white;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    min-width: 300px;
    max-width: 400px;
  }
  
  .standard-preview .preview-icon {
    margin-right: 0.75rem;
  }
  
  .standard-preview .preview-content p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  /* Custom Notification Preview */
  .custom-preview .preview-notification {
    background-color: white;
    border-left: 4px solid #4caf50;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    display: flex;
    align-items: center;
    min-width: 300px;
    max-width: 400px;
    position: relative;
  }
  
  .custom-preview .preview-icon {
    color: #4caf50;
    margin-right: 0.75rem;
    font-size: 1.1rem;
  }
  
  .custom-preview .preview-content p {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
  }
  
  /* Animation for custom preview */
  .custom-preview .preview-notification {
    animation: slideAndFade 3s infinite;
  }
  
  @keyframes slideAndFade {
    0% {
      transform: translateX(10px);
      opacity: 0;
    }
    10% {
      transform: translateX(0);
      opacity: 1;
    }
    90% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(10px);
      opacity: 0;
    }
  }
  
  
  .settings-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .settings-subtitle {
    color: var(--secondary-color, #6c757d);
    font-size: 1rem;
    margin: 0;
  }
  
  .settings-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .settings-section {
    background-color: var(--card-bg, #fff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .section-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.25rem;
  }
  
  .section-icon {
    font-size: 1.25rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  /* Profile Card */
  .settings-profile-card {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
  }
  
  .profile-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--primary-color, #4a6cf7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .profile-details {
    flex: 1;
  }
  
  .profile-name {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
    color: var(--dark-color, #343a40);
  }
  
  .profile-role {
    font-size: 0.9rem;
    color: var(--secondary-color, #6c757d);
    margin: 0;
  }
  
  /* Settings Options */
  .settings-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .settings-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
    transition: var(--transition, all 0.3s ease);
  }
  
  .settings-option:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  .option-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }
  
  .option-icon {
    color: var(--primary-color, #4a6cf7);
    font-size: 1.25rem;
  }
  
  .option-details {
    flex: 1;
  }
  
  .option-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
    color: var(--dark-color, #343a40);
  }
  
  .option-description {
    font-size: 0.85rem;
    color: var(--secondary-color, #6c757d);
    margin: 0;
  }
  
  /* Toggle Switch */
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .toggle-slider {
    background-color: var(--primary-color, #4a6cf7);
  }
  
  input:focus + .toggle-slider {
    box-shadow: 0 0 1px var(--primary-color, #4a6cf7);
  }
  
  input:checked + .toggle-slider:before {
    transform: translateX(26px);
  }
  
  /* Settings Actions */
  .settings-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border-radius: var(--border-radius, 10px);
    border: none;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .button-icon {
    font-size: 1.1rem;
  }
  
  .logout-button {
    background-color: var(--danger-color, #dc3545);
    color: white;
  }
  
  .logout-button:hover {
    background-color: #c82333;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(220, 53, 69, 0.2);
  }
  
  /* Add these styles to your SettingsPage.css file */

/* Notification Options Section */
.notification-options {
  margin-top: 1rem;
}

.notification-option-group {
  background-color: var(--white, #ffffff);
  border-radius: var(--border-radius, 10px);
  padding: 1.5rem;
  box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
}

.notification-option-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;
}

.notification-option-button {
  display: flex;
  align-items: flex-start;
  background-color: var(--light, #f8f9fa);
  border: 2px solid #eaeaea;
  border-radius: var(--border-radius, 10px);
  padding: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.notification-option-button.active {
  border-color: var(--primary-color, #4a6cf7);
  background-color: var(--primary-light, #e8effd);
}

.notification-option-icon-container {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  position: relative;
}

.notification-option-button.active .notification-option-icon-container {
  color: var(--primary-color, #4a6cf7);
}

.selected-icon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--primary-color, #4a6cf7);
  font-size: 1.25rem;
}

.notification-option-content {
  flex: 1;
}

.notification-option-content h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark, #343a40);
}

.notification-option-content p {
  margin: 0;
  font-size: 0.85rem;
  color: var(--secondary, #6c757d);
  line-height: 1.4;
}

.notification-option-button:hover {
  background-color: var(--primary-light, #e8effd);
  transform: translateY(-2px);
}

/* Notification Preview Section */
.notification-preview {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.preview-title {
  margin: 0 0 1rem 0;
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--dark, #343a40);
}

.notification-preview-box {
  padding: 1.5rem;
  border-radius: var(--border-radius, 10px);
  background-color: var(--light, #f8f9fa);
  display: flex;
  justify-content: center;
}

/* Standard Notification Preview */
.standard-preview .preview-notification {
  background-color: #388e3c;
  color: white;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  max-width: 400px;
}

.standard-preview .preview-icon {
  margin-right: 0.75rem;
}

.standard-preview .preview-content p {
  margin: 0;
  font-size: 0.9rem;
}

/* Custom Notification Preview */
.custom-preview .preview-notification {
  background-color: white;
  border-left: 4px solid #4caf50;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  display: flex;
  align-items: center;
  min-width: 300px;
  max-width: 400px;
  position: relative;
}

.custom-preview .preview-icon {
  color: #4caf50;
  margin-right: 0.75rem;
  font-size: 1.1rem;
}

.custom-preview .preview-content p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

/* Animation for custom preview */
.custom-preview .preview-notification {
  animation: slideAndFade 3s infinite;
}

@keyframes slideAndFade {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  90% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(10px);
    opacity: 0;
  }
}
