/* ClientDetail.css */

.client-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    /* margin-top: 2rem; */
    margin-bottom: 2rem;
  }
  
  /* Loading Container */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    color: var(--secondary-color, #6c757d);
  }
  
  .loading-container .spinner {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--primary-color, #4a6cf7);
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Client Header */
  .client-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .client-title-section {
    flex: 1;
  }
  
  .client-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }
  
  .client-icon {
    font-size: 2rem;
    color: var(--primary-color, #4a6cf7);
  }
  
  .client-title h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .client-subtitle {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    color: var(--secondary-color, #6c757d);
  }
  
  .client-id {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background-color: var(--primary-light, #e8effd);
    color: var(--primary-color, #4a6cf7);
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .client-partner {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background-color: var(--light-color, #f8f9fa);
    color: var(--secondary-color, #6c757d);
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  /* Client Actions */
  .client-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  .client-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.65rem 1rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    text-decoration: none;
  }
  
  .edit-button {
    background-color: var(--primary-color, #4a6cf7);
    color: white;
  }
  
  .edit-button:hover {
    background-color: #3a5bd8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(74, 108, 247, 0.2);
  }
  
  .wip-button-c {
    background-color: var(--warning-color, #ffc107);
    color: var(--dark-color, #343a40);
  }
  .client-search-container {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  
  
  .wip-button-c:hover {
    background-color: #e0a800;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(255, 193, 7, 0.2);
  }
  
  .payment-button {
    background-color: var(--success-color, #28a745);
    color: white;
  }
  
  .payment-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(40, 167, 69, 0.2);
  }
  
  .settings-button {
    background-color: var(--secondary-color, #6c757d);
    color: white;
  }
  
  .settings-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(108, 117, 125, 0.2);
  }
  
  /* Client Info Toggle */
  .client-info-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
    cursor: pointer;
    margin-bottom: 1rem;
    transition: var(--transition, all 0.3s ease);
    font-weight: 600;
    color: var(--dark-color, #343a40);
  }
  
  .client-info-toggle:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  /* Client Detail Card */
  .client-detail-card {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .client-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .section-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.15rem;
    font-weight: 600;
    color: var(--dark-color, #343a40);
    padding-bottom: 0.5rem;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .info-item label {
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--secondary-color, #6c757d);
  }
  
  .info-item span {
    font-size: 0.95rem;
    color: var(--dark-color, #343a40);
  }
  
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .status-active {
    background-color: rgba(40, 167, 69, 0.1);
    color: var(--success-color, #28a745);
  }
  
  .status-inactive {
    background-color: rgba(220, 53, 69, 0.1);
    color: var(--danger-color, #dc3545);
  }
  
  /* Tabs */
  .client-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.5rem;
  }
  
  .tab-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: none;
    border: none;
    font-weight: 600;
    color: var(--secondary-color, #6c757d);
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    border-radius: var(--border-radius, 10px) var(--border-radius, 10px) 0 0;
    position: relative;
    border-bottom: 3px solid transparent;
  }
  
  .tab-button:hover {
    color: var(--primary-color, #4a6cf7);
    background-color: var(--primary-light, #e8effd);
  }
  
  .tab-button.active {
    color: var(--primary-color, #4a6cf7);
    border-bottom-color: var(--primary-color, #4a6cf7);
  }
  
  /* Tab Content */
  .tab-content {
    background-color: var(--card-bg, #ffffff);
    border-radius: var(--border-radius, 10px);
    box-shadow: var(--box-shadow, 0 2px 10px rgba(0, 0, 0, 0.05));
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  /* Summary Tab */
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.5rem;
  }
  
  .summary-card {
    background-color: var(--light-color, #f8f9fa);
    border-radius: var(--border-radius, 10px);
    padding: 1.5rem;
    transition: var(--transition, all 0.3s ease);
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .summary-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .summary-card-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--secondary-color, #6c757d);
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .summary-icon {
    font-size: 1.25rem;
  }
  
  .summary-value {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .time-value {
    color: var(--primary-color, #4a6cf7);
  }
  
  .invoice-value {
    color: var(--warning-color, #ffc107);
  }
  
  .payment-value {
    color: var(--success-color, #28a745);
  }
  
  .wip-value {
    color: var(--info-color, #17a2b8);
  }
  
  .summary-footer {
    font-size: 0.85rem;
    color: var(--secondary-color, #6c757d);
  }
  
  /* Tab Headers */
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .tab-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--dark-color, #343a40);
    margin: 0;
  }
  
  .tab-actions {
    display: flex;
    gap: 0.75rem;
  }
  
  .toggle-button-c, .add-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.65rem 1rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    text-decoration: none;
  }
  
  .toggle-button-c {
    background-color: var(--light-color, #f8f9fa);
    color: var(--secondary-color, #6c757d);
  }
  
  .toggle-button-c:hover {
    background-color: var(--primary-light, #e8effd);
    color: var(--primary-color, #4a6cf7);
  }
  
  .add-button {
    background-color: var(--success-color, #28a745);
    color: white;
  }
  
  .add-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(40, 167, 69, 0.2);
  }
  
  /* Table Styles */
  .table-container {
    overflow-x: auto;
  }
  
  .data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 0.9rem;
  }
  
  .data-table th {
    background-color: var(--light-color, #f8f9fa);
    color: var(--dark-color, #343a40);
    font-weight: 600;
    text-align: left;
    padding: 1rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
  
  .data-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    vertical-align: middle;
  }
  
  .table-row {
    transition: var(--transition, all 0.3s ease);
  }
  
  .table-row:hover {
    background-color: var(--primary-light, #e8effd);
  }
  
  .closed-row {
    background-color: rgba(0, 0, 0, 0.025);
  }
  
  .amount-cell {
    font-weight: 600;
    font-family: 'Courier New', monospace;
    color: var(--success-color, #28a745);
    text-align: right;
  }
  
  /* Badges and Pills */
  .activity-badge, .user-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .activity-badge {
    background-color: var(--primary-light, #e8effd);
    color: var(--primary-color, #4a6cf7);
  }
  
  .user-badge {
    background-color: var(--light-color, #f8f9fa);
    color: var(--secondary-color, #6c757d);
  }
  
  .badge-icon {
    font-size: 0.85rem;
  }
  
  .status-pill {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
  }
  
  .open {
    background-color: rgba(23, 162, 184, 0.1);
    color: var(--info-color, #17a2b8);
  }
  
  .billed {
    background-color: rgba(40, 167, 69, 0.1);
    color: var(--success-color, #28a745);
  }
  
  .in-progress {
    background-color: rgba(255, 193, 7, 0.1);
    color: var(--warning-color, #ffc107);
  }
  
  .completed {
    background-color: rgba(40, 167, 69, 0.1);
    color: var(--success-color, #28a745);
  }
  
  /* Table Action Buttons */
  .table-action-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: var(--border-radius, 10px);
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
    background-color: var(--light-color, #f8f9fa);
    color: var(--secondary-color, #6c757d);
    text-decoration: none;
  }
  
  .table-action-button:hover:not(:disabled) {
    background-color: var(--primary-light, #e8effd);
    color: var(--primary-color, #4a6cf7);
  }
  
  .table-action-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .view-button {
    background-color: var(--info-color, #17a2b8);
    color: white;
  }
  
  .view-button:hover {
    background-color: #138496;
    color: white;
  }
  
  .invoice-link {
    color: var(--primary-color, #4a6cf7);
    text-decoration: none;
    font-weight: 600;
  }
  
  .invoice-link:hover {
    text-decoration: underline;
  }
  
  /* Empty States */
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    text-align: center;
  }
  
  .empty-icon {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: var(--secondary-color, #6c757d);
    opacity: 0.2;
  }
  
  .empty-state h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--dark-color, #343a40);
  }
  
  .empty-state p {
    color: var(--secondary-color, #6c757d);
    margin-bottom: 1rem;
  }
  
  .show-all-button {
    display: inline-flex;
    align-items: center;
    padding: 0.65rem 1rem;
    background-color: var(--primary-light, #e8effd);
    color: var(--primary-color, #4a6cf7);
    border: none;
    border-radius: var(--border-radius, 10px);
    font-weight: 600;
    font-size: 0.85rem;
    cursor: pointer;
    transition: var(--transition, all 0.3s ease);
  }
  
  .show-all-button:hover {
    background-color: var(--primary-color, #4a6cf7);
    color: white;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 992px) {
    .client-header {
      flex-direction: column;
    }
    
    .client-actions {
      margin-top: 1.5rem;
      width: 100%;
    }
    
    .client-action-button {
      flex: 1;
      justify-content: center;
    }
    
    .client-info-grid {
      grid-template-columns: 1fr;
    }
    
    .summary-cards {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .client-detail-container {
      padding: 1rem;
    }
    
    .client-tabs {
      flex-direction: column;
      gap: 0.5rem;
    }
    
    .tab-button {
      width: 100%;
      justify-content: center;
    }
    
    .tab-actions {
      width: 100%;
    }
    
    .toggle-button, .add-button {
      flex: 1;
      justify-content: center;
    }
    
    .summary-cards {
      grid-template-columns: 1fr;
    }
    
    .table-action-button span {
      display: none;
    }
  }